import React, { useState, useEffect } from "react";
import AdminDashboard from "./AdminDashboard";
import "../../assets/css/admin/allcourses.css";

const SAPendingCourses = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz//studyabroad/pending"
      );
      const data = await response.json();
      setCourses(data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  const handleDelete = async (courseId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz//studyabroad/delete/${courseId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setCourses(courses.filter((course) => course.id !== courseId));
      } else {
        console.error("Failed to delete course");
      }
    } catch (error) {
      console.error("Error deleting course:", error);
    }
  };

  const handleApprove = async (courseId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz//studyabroad/approve/${courseId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setCourses(courses.filter((course) => course.id !== courseId));
        window.alert("Course Approved");
      } else {
        console.error("Failed to approve course");
      }
    } catch (error) {
      console.error("Error approving course:", error);
    }
  };

  return (
    <div>
      <AdminDashboard />
      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>PENDING COURSES</h1>
          <form>
            <input type="text" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>
        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>Country</th>
                  <th>University / Institute</th>
                  <th>Course / Degree Name</th>
                  <th>Level</th>
                  <th>Duration</th>
                  <th>Start Date</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => (
                  <tr key={course.id}>
                    <td>{course.country}</td>
                    <td>{course.university}</td>
                    <td>{course.courseName}</td>
                    <td>{course.level}</td>
                    <td>{course.duration}</td>
                    <td>
                      {new Date(course.startDate).toLocaleDateString("en-CA")}
                    </td>
                    <td>
                      <a href="#!" id="view-website">
                        WEBSITE
                      </a>
                    </td>
                    <td id="action-buttons">
                      <a
                        href="#!"
                        id="app-details"
                        onClick={() => handleApprove(course.id)}
                      >
                        APPROVE
                      </a>
                      <a
                        href="#!"
                        id="del-details"
                        onClick={() => handleDelete(course.id)}
                      >
                        DELETE
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SAPendingCourses;

import React from 'react'
import InstituteDashboard from './InstituteDashboard'
import axios from 'axios'

const Appliedstudents = () => {

  const id = sessionStorage.getItem('id');

  // fetch data from the database using id

  return (

    <div>

      <InstituteDashboard />



    </div>

  )
}

export default Appliedstudents
import React, { useEffect, useRef, useState } from "react";
import InstituteDashboard from "./InstituteDashboard";
import "../../assets/css/Institute/Editprofile.css";
import intlTelInput from "intl-tel-input";
import utilsScript from "intl-tel-input/build/js/utils";
import "intl-tel-input/build/css/intlTelInput.css"; // Assuming CSS import is necessary
import Swal from "sweetalert2";
import axios from "axios";

const EditProfile = () => {
  const inputRef = useRef(null); //ref object for institute phone number

  const BaseUrl = "https://skillbackend.educationesupport.xyz//ins/";

  useEffect(() => {
    if (inputRef.current) {
      intlTelInput(inputRef.current, {
        initialCountry: "",
        separateDialCode: true,
        utilsScript: utilsScript,
      });
    }
  }, []);

  const [formData, setFormData] = useState({
    inname: "",
    inWebsite: "",
    inEmail: "",
    inPhone: "",
    inaddress: "",
    inlocation: "",
    inlogo: "",
    indescription: "",
  });

  const id = sessionStorage.getItem("id");

  const getOneData = async () => {
    axios
      .get(`${BaseUrl}/one/${id}`)
      .then((response) => {
        setFormData({
          inname: response.data[0].in_name,
          inEmail: response.data[0].email,
          inWebsite: response.data[0].website,
          inPhone: response.data[0].tel,
          inaddress: response.data[0].address,
          inlocation: response.data[0].token,
          inlogo: response.data[0].img,
          indescription: response.data[0].desc_,
        });
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOneData();
  }, [id]);

  //Institute Register form

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]:
        e.target.name === "inlogo" ? e.target.files[0] : e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formdata1 = new FormData();

    formdata1.append("in_name", formData.inname);
    formdata1.append("website", formData.inWebsite);
    formdata1.append("email", formData.inEmail);
    formdata1.append("tel", formData.inPhone);
    formdata1.append("address", formData.inaddress);
    formdata1.append("img", formData.inlogo);
    formdata1.append("desc", formData.indescription);
    formdata1.append("token", formData.inlocation);

    axios
      .put(
        "https://skillbackend.educationesupport.xyz//ins/update/" + id,
        formdata1
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    Swal.fire({
      position: "center",
      icon: "success",
      title: "Successfully registered",
      showConfirmButton: false,
      timer: 1500,
    }).then(() => {
      window.location.href = "/insprofile";
    });
  };

  return (
    <div>
      <InstituteDashboard />

      <div className="content-edit-profile">
        <div>
          <div className="instituteregister">
            <div className="head-content-text">
              <h4>Edit Profile</h4>
            </div>

            <div className="form-content">
              <form onSubmit={handleSubmit}>
                <div className="form-group-1">
                  <i class="fa-solid fa-building-columns"></i>
                  <input
                    type="text"
                    placeholder="Enter Institute Name"
                    name="inname"
                    value={formData.inname}
                    id="name"
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group-1">
                  <i class="fa-solid fa-globe"></i>
                  <input
                    type="url"
                    placeholder="Enter Institute Website"
                    name="inWebsite"
                    value={formData.inWebsite}
                    id="Website"
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group-1">
                  <i class="fa-solid fa-envelope"></i>
                  <input
                    type="Email"
                    placeholder="Enter Institute Email"
                    name="inEmail"
                    value={formData.inEmail}
                    id="Email"
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group-1" id="form-con">
                  <i class="fa-solid fa-phone"></i>
                  <input
                    type="tel"
                    value={formData.inPhone}
                    id="mobile_code"
                    defaultValue=""
                    name="inPhone"
                    placeholder="Enter phone number"
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group-1">
                  <i class="fa-solid fa-address-book"></i>
                  <input
                    type="address"
                    placeholder="Enter Institute Address"
                    name="inaddress"
                    value={formData.inaddress}
                    id="address"
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group-1">
                  <i class="fa-solid fa-location-dot"></i>
                  <input
                    type="url"
                    placeholder="Enter Institute location"
                    name="inlocation"
                    value={formData.inlocation}
                    id="location"
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group-1">
                  <img
                    src={
                      `https://skillbackend.educationesupport.xyz//Upload/Images/` +
                      formData.inlogo
                    }
                    alt=""
                    width={300}
                  />
                </div>
                <div className="form-group-1">
                  <label for="file-input" class="drop-container">
                    <span class="drop-title">Change Logo</span>
                    <input
                      type="file"
                      accept="image/*"
                      required=""
                      id="file-input"
                      name="inlogo"
                      onChange={handleChange}
                    />
                  </label>
                </div>

                <div className="form-group-1 textarea">
                  <textarea
                    name="indescription"
                    id=""
                    placeholder="Enter the description of the Institute background........ "
                    onChange={handleChange}
                    value={formData.indescription}
                  ></textarea>
                </div>
                <div className="form-group-1">
                  <input
                    type="submit"
                    name="submit"
                    value="Update"
                    onChange={handleChange}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;

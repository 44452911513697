import React, { useState } from 'react';
import '../../assets/css/admin/signup.css';
import Img from '../../assets/images/skillslogo.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SignUp = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            alert('Passwords do not match');
        } else {
            console.log('Form submitted');
        }
    };

    return (
        <div className="signup-container">
            <div className="signup-box">
                <div className="signup-section">
                    <a href="/"><img src={Img} alt="logo.png" /></a>
                </div>
                <h2>SIGN UP</h2>
                <form onSubmit={handleSubmit}>
                    <div className="signup-input-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            required
                        />
                    </div>
                    <div className="signup-input-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            required
                        />
                    </div>
                    <div className="signup-input-group password-container">
                        <label htmlFor="password">Password</label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <span
                            className="signup-toggle-visibility"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? < FaEye/> : < FaEyeSlash />}
                        </span>
                    </div>
                    <div className="signup-input-group password-container">
                        <label htmlFor="confirm-password">Confirm Password</label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="confirm-password"
                            name="confirm-password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        <span
                            className="signup-toggle-visibility"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? < FaEye/> : < FaEyeSlash />}
                        </span>
                    </div>
                    <div className="signup-input-group checkbox-group">
                        <input type="checkbox" id="remember" name="remember" />
                        <label htmlFor="remember">Remember me</label>
                    </div>
                    <button type="submit" className="signup-button">Sign Up</button>
                </form>
                <p className="signup-text">
                    Already have an account? <a href="/Login">Sign In</a>
                </p>
            </div>
        </div>
    );
};

export default SignUp;

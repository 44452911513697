import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../assets/css/eventview.css";
import "../assets/css/courseView.css";
import SecondNav from "../components/SecondNav";
import Footer from "../components/Footer";
import axios from "axios";

const LanguageQualificationView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [langQualifications, setLangQualifications] = useState([]);

  useEffect(() => {
    const fetchLangQualificationsData = async () => {
      try {
        const response = await axios.get(
          `https://skillbackend.educationesupport.xyz//langQualifications/${id}`
        );
        console.log(response.data);
        setLangQualifications(response.data);
      } catch (error) {
        console.error("Error fetching Language Qualification data:", error);
      }
    };

    fetchLangQualificationsData();
  }, [id]);

  if (!langQualifications) {
    return null;
  }

  const handleContactUs = () => {
    navigate("/contact");
  };

  return (
    <div>
      <SecondNav />
      <div className="c_container">
        <div className="c_leftcolumn">
          <div className="programme">
            <h2>{langQualifications.langQualName}</h2>
            <hr id="hr-programme" />
            {/* <div className="c-image">
                        {langQualifications.logo && <img src={`https://skillbackend.educationesupport.xyz//Upload/images/loans/${stdLoans.logo}`} alt={stdLoans.degreeName} />}
                    </div> */}
            <h3>Overview</h3>
            <p id="p-overview">
              {langQualifications.description || "No description available"}
            </p>
            <p>
              Visit{" "}
              <a href={langQualifications.instituteWebsite}>
                {langQualifications.instituteWebsite}
              </a>
            </p>
            <h3>Send Inquiry</h3>
            <button type="cbutton" onClick={handleContactUs}>
              Contact Us
            </button>
          </div>
          {/* 
                <div className="programmes">
                <h3>RELATED External Degrees</h3>
                <hr id="hr-programme" />
                <div className="courseitem">
                    <div className="coursetitle">
                    <a href="#"><strong>Master Of Business Management</strong></a>
                    </div>
                    <div className="coursedetails">Duration: 2 Years</div>
                    <hr />
                    <div className="courseins">By: <a href="#">{langQualifications.university}</a></div>
                </div>
                </div> */}

          {/* <div className="programme">
                    <ApplyForm />
                </div> */}
        </div>

        <div className="c_rightcolumn">
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-building"></i>
                    <span>Institute:</span>
                  </div>
                </td>
                <td>
                  <p>{langQualifications.university}</p>
                </td>
              </tr>

              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-book-reader"></i>
                    <span>Teaching Method:</span>
                  </div>
                </td>
                <td>
                  <p>{langQualifications.teachingMethod}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-hourglass-start"></i>
                    <span>Duration:</span>
                  </div>
                </td>
                <td>
                  <p>{langQualifications.duration}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-money-bill-alt"></i>
                    <span>Course Fee:</span>
                  </div>
                </td>
                <td>
                  <p>
                    {langQualifications.courseFee ||
                      "Course fee details not available"}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa-solid fa-wallet"></i>
                    <span>Pay Method:</span>
                  </div>
                </td>
                <td>
                  <p>{langQualifications.payMethod}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-phone"></i>
                    <span>Contact:</span>
                  </div>
                </td>
                <td>
                  <p>{langQualifications.institutePhone}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-address-card"></i>
                    <span>Address:</span>
                  </div>
                </td>
                <td>
                  <p>{langQualifications.instituteAddress}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-envelope"></i>
                    <span>Email:</span>
                  </div>
                </td>
                <td>
                  <p>
                    <a href={`mailto:${langQualifications.instituteEmail}`}>
                      {langQualifications.instituteEmail}
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-map-marker-alt"></i>
                    <span>Location:</span>
                  </div>
                </td>
                <td>
                  <p>{langQualifications.instituteLocation}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LanguageQualificationView;

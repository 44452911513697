import React from 'react'
import { useNavigate } from 'react-router-dom'
import SecondNav from '../components/SecondNav'


const GuidanceProgram = () => {

  const navigate = useNavigate();

  const handleContactUs = () => {
    navigate('/contact');
  };

  return (

    <div>

      <SecondNav />

      <div className="about-us">

        <section className="discover">
          <div className="discover-content">

            <div className="discover-text">

              <h2 style={{ textTransform: "uppercase" }} >Meet a Carrier  Guidance advicer</h2>
              <h1 style={{ color: "#d3113b" }}>SKILLS SRI LANKA!</h1>

              <div className="discover-image"></div>

              <p>
                Please select the career guidance service you require by clicking "Book Now." Our team will immediately contact you to confirm the session date and time.
                Speak to a career guidance advisor to clear your questions regarding education and career needs from the comfort of your home.
              </p>

              <p>A scientifically backed career consultation to help you plan your higher education, vocational education, or career</p>

              <h3 style={{ fontWeight: "bolder", textAlign: "left" }}>Skills Sri Lanka Team Offers:</h3>
              <ul>
                <li><strong>Analysis of your career test</strong></li>
                <li><strong>Finding the most suitable Higher Education, Vocational Education, Degree, or Course for you</strong> </li>
                <li><strong>Identifying the best career options for your life situation</strong></li>
              </ul>

              <p>Sessions are available in Sinhala, Tamil, or English.<br />
                Delivery via phone call or online video call.</p>

              <button type="cbutton" style={{marginTop:"10px"}} onClick={handleContactUs}>Book Now</button>

            </div>
          </div>
        </section>
      </div>


    </div>

  )

}

export default GuidanceProgram
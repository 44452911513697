import React from 'react'
import Img1 from '../assets/images/govUni/colombo.png'
// import home from '../assets/images/home.png';
// import event from '../assets/images/institute.png';
import '../assets/css/instituteview.css'
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';


const GovUniView = () => {

    return (
        <div className='institutesView'>
            <SecondNav/>
            {/* <div className="homelink">
                <ul className='nav-link'>
                    <li><img src={home} alt="" /></li>
                    <li id='nav-link-img'> <img src={event} alt="" /></li>
                </ul>
            </div> */}

            <div className="inview">

                <div className="block-one-inview">

                    <div className="heading-text-inview">
                        <h3>University of Colombo</h3>
                        <hr id="hr-inview" />
                    </div>

                    <div className="img-inview">
                        <img src={Img1} alt="" width={300} />
                    </div>

                    <div className="content-inview">

                        <p>The University of Colombo (informally Colombo University or UoC) is a public research university located primarily in Colombo, Sri Lanka. It is the oldest institution of modern higher education in Sri Lanka. Specialised in the fields of natural, social, and applied sciences as well as mathematics, computer sciences, law, and Technology. It is ranked among the top 10 universities in South Asia.</p>
                        <p>The University of Colombo was founded in 1921 as University College Colombo, affiliated to the University of London. Degrees were issued to its students from 1923 onwards. The university traces its roots to 1870 when the Ceylon Medical School was established. UoC has produced notable alumni in the fields of science, law, economics, business, literature, and politics.</p>
                    </div>

                    <div className="map-section">
                    <iframe  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1013995.8542937454!2d78.63931137812504!3d6.900014899999988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2596309dfdd3f%3A0x45a4b0e7834ac0d4!2sUniversity%20of%20Colombo!5e0!3m2!1sen!2slk!4v1721375379529!5m2!1sen!2slk"> </iframe> 
                    </div>

                </div>

                <div className="block-two-inview">
                    <ul>
                        <li><strong><i class="fa-solid fa-globe"></i> Website: </strong> <a href='https://cmb.ac.lk/'>
                        https://cmb.ac.lk/</a>
                        </li>
                        <li><strong><i class="fa-solid fa-envelope"></i> Email: </strong> <p>ucr@cmb.ac.lk</p></li>
                        <li><strong><i class="fa-solid fa-phone"></i>Contact;   </strong> <p>(+94) 112 581 835</p></li>
                        <li><strong><i class="fa-solid fa-address-book"></i>Address: </strong> <address><p>College House</p>
                        <p>94, Cumaratunga Munidasa Mw</p>  <p>Colombo 03 </p> Sri Lanka</address></li>
                    </ul>
                </div>



            </div>


            <div className="course-list">

                <div className="heading-text-inview course-block">
                    <h3>COURSES @ University of Colombo</h3>
                    <hr id="hr-inview" />
                </div>

                <div className="course-list-block">
                    <ul>
                        <li>
                            <div className="course-box">
                                <h3>Microsoft Office Applications</h3>
                                <p><strong>Duration : </strong> 16 Weeks</p>
                                <p><strong>By </strong> University of Colombo</p>
                            </div>
                        </li>

                        <li>
                            <div className="course-box">
                                <h3>Microsoft Office Applications</h3>
                                <p><strong>Duration : </strong> 16 Weeks</p>
                                <p><strong>By </strong> University of Colombo</p>
                            </div>
                        </li>

                        <li>
                            <div className="course-box">
                                <h3>Microsoft Office Applications</h3>
                                <p><strong>Duration : </strong> 16 Weeks</p>
                                <p><strong>By </strong> University of Colombo</p>
                            </div>
                        </li>

                        <li>
                            <div className="course-box">
                                <h3>Microsoft Office Applications</h3>
                                <p><strong>Duration : </strong> 16 Weeks</p>
                                <p><strong>By </strong> University of Colombo</p>
                            </div>
                        </li>

                    </ul>

                    <div className="view-all-button">
                        <a href="">SEE ALL</a>
                    </div>
                </div>
            </div>
            <Footer />


        </div>
    )
}

export default GovUniView;
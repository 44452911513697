import React, { useState, useEffect } from "react";
import InstituteDashboard from "./InstituteDashboard";

const ManageStdLoans = () => {
  // const [stdLoans, setStdLoans] = useState([]);
  // const [editingStdLoansId, setEditingStdLoansId] = useState(null);
  // const [editedStdLoans, setEditedStdLoans] = useState({});

  // const instituteId = sessionStorage.getItem('id');

  // useEffect(() => {
  //     if (instituteId) {
  //         fetchStdLoans();
  //     }
  // }, [instituteId]);

  // const fetchStdLoans = async () => {
  //     try {
  //         const response = await fetch(`https://skillbackend.educationesupport.xyz//stdLoans/all?institute_id=${instituteId}`);
  //         if (!response.ok) {
  //             throw new Error('Network response was not ok');
  //         }
  //         const data = await response.json();
  //         setStdLoans(data);
  //     } catch (error) {
  //         console.error('Error fetching stdLoans:', error);
  //     }
  // };

  // const handleDelete = async (StdLoansId) => {
  //     try {
  //         const response = await fetch(`https://skillbackend.educationesupport.xyz//stdLoans/delete/${StdLoansId}`, {
  //             method: 'POST'
  //         });
  //         if (response.ok) {
  //             setStdLoans(stdLoans.filter(stdLoans => stdLoans.id !== StdLoansId));
  //         } else {
  //             console.error('Failed to delete stdLoans');
  //         }
  //     } catch (error) {
  //         console.error('Error deleting stdLoans:', error);
  //     }
  // };

  // const handleEdit = (stdLoans) => {
  //     setEditingStdLoansId(stdLoans.id);
  //     setEditedStdLoans(stdLoans);
  // };

  // const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setEditedStdLoans({ ...editedStdLoans, [name]: value });
  // };

  // const handleSave = async (StdLoansId) => {
  //     try {
  //         const response = await fetch(`https://skillbackend.educationesupport.xyz//stdLoans/update/${StdLoansId}`, {
  //             method: 'POST',
  //             headers: {
  //                 'Content-Type': 'application/json'
  //             },
  //             body: JSON.stringify(editedStdLoans)
  //         });
  //         if (response.ok) {
  //             setStdLoans(stdLoans.map(stdLoans => stdLoans.id === StdLoansId ? editedStdLoans : stdLoans));
  //             setEditingStdLoansId(null);
  //         } else {
  //             console.error('Failed to save stdLoans');
  //         }
  //     } catch (error) {
  //         console.error('Error saving stdLoans:', error);
  //     }
  // };

  // const handleCancel = () => {
  //     setEditingStdLoansId(null);
  //     setEditedStdLoans({});
  // };

  const [StdLoan, setStdLoan] = useState([]);

  useEffect(() => {
    fetchStdLoan();
  }, []);

  const fetchStdLoan = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz//StdLoans/all"
      );
      const data = await response.json();
      setStdLoan(data);
    } catch (error) {
      console.error("Error fetching Student Loans:", error);
    }
  };

  const handleDelete = async (StdLoanId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz//StdLoans/delete/${StdLoanId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setStdLoan(StdLoan.filter((StdLoan) => StdLoan.id !== StdLoanId));
      } else {
        console.error("Failed to delete Student Loan");
      }
    } catch (error) {
      console.error("Error deleting Student Loan:", error);
    }
  };

  return (
    <div>
      <InstituteDashboard />
      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>MANAGE STUDENT LOAN</h1>
          <form>
            <input type="text" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>

        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>Degree Name</th>
                  <th>Institute Name</th>
                  <th>Start Date</th>
                  <th>Duration</th>
                  <th>Contact No.</th>
                  <th>Email</th>
                  <th>Location</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>
              {/* <tbody>
                                {stdLoans.map((stdLoans) => (
                                    <tr key={stdLoans.id}>
                                        <td>
                                            {editingStdLoansId === stdLoans.id ? (
                                                <input
                                                    type="text"
                                                    name="bankName"
                                                    value={editedStdLoans.bankName}
                                                    onChange={handleChange}
                                                />
                                            ) : (
                                                stdLoans.bankName
                                            )}
                                        </td>
                                        <td>
                                            {editingStdLoansId === stdLoans.id ? (
                                                <input
                                                    type="text"
                                                    name="stdLoanName"
                                                    value={editedStdLoans.stdLoanName}
                                                    onChange={handleChange}
                                                />
                                            ) : (
                                                stdLoans.stdLoanName
                                            )}
                                        </td>
                                        <td>
                                            {editingStdLoansId === stdLoans.id ? (
                                                <input
                                                    type="text"
                                                    name="university"
                                                    value={editedStdLoans.university}
                                                    onChange={handleChange}
                                                />
                                            ) : (
                                                stdLoans.university
                                            )}
                                        </td>
                                        <td>
                                            {editingStdLoansId === stdLoans.id ? (
                                                <input
                                                    type="text"
                                                    name="institutePhone"
                                                    value={editedStdLoans.institutePhone}
                                                    onChange={handleChange}
                                                />
                                            ) : (
                                                stdLoans.institutePhone
                                            )}
                                        </td>
                                        <td>
                                            {editingStdLoansId === stdLoans.id ? (
                                                <input
                                                    type="text"
                                                    name="instituteAddress"
                                                    value={editedStdLoans.instituteAddress}
                                                    onChange={handleChange}
                                                />
                                            ) : (
                                                stdLoans.instituteAddress
                                            )}
                                        </td>
                                        <td>
                                            {editingStdLoansId === stdLoans.id ? (
                                                <input
                                                    type="text"
                                                    name="instituteEmail"
                                                    value={editedStdLoans.instituteEmail}
                                                    onChange={handleChange}
                                                />
                                            ) : (
                                                stdLoans.instituteEmail
                                            )}
                                        </td>

                                        <td>
                                            <a href={stdLoans.link} target="_blank" rel="noopener noreferrer" id='view-web'>Link</a>
                                        </td>
                                        <td id='action-buttons'>
                                            {editingStdLoansId === stdLoans.id ? (
                                                <>
                                                    <a href="#!" id='man-details' onClick={() => handleSave(stdLoans.id)}>SAVE</a>
                                                    <a href="#!" id='del-details' onClick={handleCancel}>CANCEL</a>
                                                </>
                                            ) : (
                                                <>
                                                    <a href="#!" id='man-details' onClick={() => handleEdit(stdLoans)}>EDIT</a>
                                                    <a href="#!" id='del-details' onClick={() => handleDelete(stdLoans.id)}>DELETE</a>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody> */}
              <tbody>
                {StdLoan.map((StdLoan) => (
                  <tr key={StdLoan.id}>
                    <td>{StdLoan.degreeName}</td>
                    <td>{StdLoan.university}</td>
                    <td>
                      {new Date(StdLoan.startDate).toLocaleDateString("en-CA")}
                    </td>
                    <td>{StdLoan.duration}</td>
                    <td>{StdLoan.institutePhone}</td>
                    <td>{StdLoan.instituteEmail}</td>
                    <td>{StdLoan.instituteLocation}</td>
                    <td>
                      <a href="#!" id="view-website">
                        WEBSITE
                      </a>
                    </td>
                    <td id="action-buttons">
                      {/* <a href="#!" id='man-details' onClick={() => handleEdit(course.id)}>EDIT</a> */}
                      <a
                        href="#!"
                        id="del-details"
                        onClick={() => handleDelete(StdLoan.id)}
                      >
                        DELETE
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageStdLoans;

import React, { useState } from "react";
import "../assets/css/bank.css";
import Swal from "sweetalert2";
import uniqid from "uniqid";

const BankCreateStLoan = () => {
  const [formData, setFormData] = useState({
    id: uniqid(),
    logo: null,
    bankName: "",
    stdLoanName: "",
    description: "",
    phone: "",
    address: "",
    email: "",
    location: "",
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    fetch("https://skillbackend.educationesupport.xyz//bankStdLoan/create", {
      method: "POST",
      body: data,
    })
      .then((response) => {
        if (response.status === 400) {
          return response.text().then((message) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: message,
              showConfirmButton: true,
            });
          });
        } else if (response.ok) {
          return response.text().then((data) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Student Loan Added Successfully",
              text: "Your student loan has been added successfully.",
              showConfirmButton: false,
              timer: 2500,
            });
          });
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          showConfirmButton: true,
        });
      });
  };

  return (
    <main className="bank-create-stdLoan">
      <div className="head-content-text">
        <h4>ADD STUDENT LOAN</h4>
      </div>
      <div className="r_container">
        <form onSubmit={handleSubmit}>
          <div className="new">
            <div className="formgroup">
              <label htmlFor="file-input" className="drop-container">
                <span className="drop-title">Drop Logo</span>
                <input
                  type="file"
                  accept="image/*"
                  id="file-input"
                  name="logo"
                  onChange={handleChange}
                />
              </label>
            </div>

            <div className="formgroup">
              <i className="fas fa-list-alt"></i>
              <input
                type="text"
                id="bankName"
                name="bankName"
                value={formData.bankName}
                onChange={handleChange}
                placeholder="Bank Name"
                required
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-book-reader"></i>
              <select
                id="stdLoanName"
                name="stdLoanName"
                value={formData.stdLoanName}
                onChange={handleChange}
                placeholder="Student Loan Name"
              >
                <option value="" disabled>
                  Select Student Loan Name
                </option>
                <option value="Student Interest Free Loan">
                  Student Interest Free Loan
                </option>
                <option value="Other Student Loans">Other Student Loans</option>
                <option value="Foreign Study Loans">Foreign Study Loans</option>
                <option value="Laptop Loans">Laptop Loans</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="formgroup textarea">
              <textarea
                name="description"
                placeholder="Enter the description for Student Loan..."
                onChange={handleChange}
                value={formData.description}
              ></textarea>
            </div>

            {/* Contact Details */}
            <div className="formgroup">
              <h4>Contact Details</h4>
            </div>

            <div className="formgroup">
              <i className="fas fa-phone"></i>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Contact No."
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-address-card"></i>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Address"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-map-marker-alt"></i>
              <input
                type="text"
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="Location"
              />
            </div>
          </div>
          <button type="submit">Add</button>
        </form>
      </div>
    </main>
  );
};

export default BankCreateStLoan;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/courseView.css";
import ApplyForm from "../components/ApplyNow";
import SecondNav from "../components/SecondNav";
import Footer from "../components/Footer";
import axios from "axios";

const TuitionView = () => {
  const { id } = useParams();
  const [tuition, setTuitions] = useState([]);

  useEffect(() => {
    const fetchTuitionData = async () => {
      try {
        const response = await axios.get(
          `https://skillbackend.educationesupport.xyz//tuition/${id}`
        );
        console.log(response.data);
        setTuitions(response.data);
      } catch (error) {
        console.error("Error fetching tuition data:", error);
      }
    };

    fetchTuitionData();
  }, [id]);

  if (!tuition) {
    return null;
  }

  return (
    <div>
      <SecondNav />

      <div className="c_container">
        <div className="c_leftcolumn">
          <div className="programme">
            <h2>{tuition.className}</h2>
            <hr id="hr-programme" />
            <div className="c-image">
              {tuition.cbanner && (
                <img
                  src={`https://skillbackend.educationesupport.xyz//Upload/images/tuitions/${tuition.cbanner}`}
                  alt={tuition.className}
                />
              )}
            </div>
            <h3>Overview</h3>
            <p id="p-overview">
              {tuition.description || "No description available"}
            </p>
          </div>

          <div className="programmes">
            <h3>RELATED TUITIONS</h3>
            <hr id="hr-programme" />
            <div className="courseitem">
              <div className="coursetitle">
                <a href="#">
                  <strong>{tuition.className}</strong>
                </a>
              </div>
              <p></p>
              <div className="coursedetails">Duration: 2 Years</div>
              <div className="coursedetails">Field: {tuition.subject}</div>
              <hr />
              <div className="courseins">
                By: <a href="#">{tuition.teacherName}</a>
              </div>
            </div>
          </div>

          <div className="programme">
            <ApplyForm />
          </div>
        </div>

        <div className="c_rightcolumn">
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-user"></i>
                    <span>Teacher Name:</span>
                  </div>
                </td>
                <td>
                  <p>{tuition.teacherName}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-graduation-cap"></i>
                    <span>Teacher Qualification:</span>
                  </div>
                </td>
                <td>
                  <p>{tuition.teacherQualification}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-clock"></i>
                    <span>Time:</span>
                  </div>
                </td>
                <td>
                  <p>
                    {new Date(`1970-01-01T${tuition.time}Z`).toLocaleTimeString(
                      "en-US",
                      { hour: "2-digit", minute: "2-digit", hour12: true }
                    )}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-calendar-alt"></i>
                    <span>Start Date: </span> <td></td>
                  </div>
                </td>
                <td>
                  <p>
                    {new Date(tuition.startDate).toLocaleDateString("en-CA")}
                  </p>
                </td>
              </tr>

              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-layer-group"></i>
                    <span>Class Level:</span>
                  </div>
                </td>
                <td>
                  <p>{tuition.classLevel}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-book"></i>
                    <span>Subject:</span>
                  </div>
                </td>
                <td>
                  <p>{tuition.subject}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-list-alt"></i>
                    <span>Class Type:</span>
                  </div>
                </td>
                <td>
                  <p>{tuition.classType}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-phone"></i>
                    <span>Contact:</span>
                  </div>
                </td>
                <td>
                  <p>{tuition.contact}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-envelope"></i>
                    <span>Email:</span>
                  </div>
                </td>
                <td>
                  <p>
                    <a href={tuition.teacherEmail}>{tuition.teacherEmail}</a>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className=" fa fa-map-marker"></i>
                    <span>District:</span>
                  </div>
                </td>
                <td>
                  <p>{tuition.district}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-map-marker-alt "></i>
                    <span>Location:</span>
                  </div>
                </td>
                <td>
                  <p>{tuition.location}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-money-bill-alt"></i>
                    <span>Class Fee:</span>
                  </div>
                </td>
                <td>
                  <p>
                    {tuition.classFee || "Course fee details not available"}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TuitionView;

import React, { useState, useEffect } from "react";
import AdminDashboard from "./AdminDashboard";
import "../../assets/css/admin/allcourses.css";

const AllIntSchls = () => {
  const [internationalSchools, setInternationalSchools] = useState([]);

  useEffect(() => {
    fetchInternationalSchools();
  }, []);

  const fetchInternationalSchools = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz//internationalSchools/all"
      );
      const data = await response.json();
      setInternationalSchools(data);
    } catch (error) {
      console.error("Error fetching international schools:", error);
    }
  };

  const handleDelete = async (schoolId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz//internationalSchools/delete/${schoolId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setInternationalSchools(
          internationalSchools.filter((school) => school.id !== schoolId)
        );
        window.alert("International School deleted");
      } else {
        console.error("Failed to delete international school");
      }
    } catch (error) {
      console.error("Error deleting international school:", error);
    }
  };

  return (
    <div>
      <AdminDashboard />
      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>ALL INTERNATIONAL SCHOOLS</h1>
          <form>
            <input type="text" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>
        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>School Name</th>
                  <th>Contact Person</th>
                  <th>Email</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {internationalSchools.map((school) => (
                  <tr key={school.id}>
                    <td>{school.intschlName}</td>
                    <td>{school.commonPhone}</td>
                    <td>{school.commonEmail}</td>
                    <td>
                      <a
                        href={school.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visit Website
                      </a>
                    </td>
                    <td id="action-buttons">
                      <a
                        href="#!"
                        id="del-details"
                        onClick={() => handleDelete(school.id)}
                      >
                        DELETE
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllIntSchls;

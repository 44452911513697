import React, { useEffect, useState } from "react";
import SADashboard from "./SADashboard";
import "../../assets/css/Institute/Instituteprofile.css";
import axios from "axios";

const SAProfileView = () => {
  const id = sessionStorage.getItem("id");

  const BaseUrl = "https://skillbackend.educationesupport.xyz//ins/";

  const [institute, setInstitute] = useState({
    id: "",
    name: "",
    email: "",
    website: "",
    phone: "",
    address: "",
    location: "",
    logo: "",
    description: "",
    token: "",
  });

  const getOneData = async () => {
    axios
      .get(`${BaseUrl}/one/${id}`)
      .then((response) => {
        setInstitute({
          id: response.data[0].id,
          name: response.data[0].in_name,
          email: response.data[0].email,
          website: response.data[0].website,
          phone: response.data[0].tel,
          address: response.data[0].address,
          location: response.data.location,
          logo: response.data[0].img,
          description: response.data[0].desc_,
          token: response.data[0].token,
        });
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOneData();
  }, []);

  return (
    <div>
      <SADashboard />

      <div className="institute-container">
        <div className="admin-profile-edit">
          <button>
            {" "}
            <a href={`/editprofile/${id}`}>
              Edit profile <i class="fa-regular fa-pen-to-square"></i>
            </a>
          </button>
        </div>

        <div className="institute-admin-box1">
          <div className="institute-box-profile">
            <div className="heading-text-inview">
              <h3>{institute.name}</h3>
              <hr id="hr-inview" />
            </div>

            <div className="img-inview">
              <img
                src={
                  `https://skillbackend.educationesupport.xyz//Upload/Images/` +
                  institute.logo
                }
                alt=""
                width={300}
              />
            </div>

            <div className="content-inview">
              <p>{institute.description}</p>
            </div>
          </div>

          <div className="institute-box-profile">
            <div className="block-two-inview">
              <ul>
                <li>
                  <strong>
                    <i class="fa-solid fa-globe"></i> Website{" "}
                  </strong>{" "}
                  <a href={institute.website}>{institute.website}</a>
                </li>
                <li>
                  <strong>
                    <i class="fa-solid fa-envelope"></i> Email{" "}
                  </strong>{" "}
                  <p>{institute.email}</p>
                </li>
                <li>
                  <strong>
                    <i class="fa-solid fa-phone"></i>Contact{" "}
                  </strong>{" "}
                  <p>{institute.phone}</p>
                </li>
                <li>
                  <strong>
                    <i class="fa-solid fa-address-book"></i>Address{" "}
                  </strong>{" "}
                  <address> {institute.address}</address>
                </li>
              </ul>
            </div>

            <div className="map-section">
              <iframe src={institute.token} allowfullscreen=""></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SAProfileView;

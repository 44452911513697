import React, { useState, useEffect } from "react";
import AdminDashboard from "./AdminDashboard";
import axios from "axios";
import Swal from "sweetalert2";

const AdvertisementManage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://skillbackend.educationesupport.xyz//advertisement/getAll")
      .then((res) => {
        setData(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <AdminDashboard />

      <div className="AllViews-admin">
        <div className="search-icon-admin admin-institute-search">
          <h1>ALL ADVERTISEMENT</h1>
          <div>
            <button
              id="del-details"
              style={{
                width: "auto",
                marginBottom: "10px",
                padding: "8px",
                backgroundColor: "blue",
                color: "white",
              }}
            >
              {" "}
              <a href="/addadvertise" style={{ color: "white" }}>
                Add Advertisement
              </a>{" "}
            </button>
          </div>
        </div>

        <div className="scroller-box">
          <div className="table-institute">
            <table>
              <thead>
                <tr>
                  <th>Advertisement Position</th>
                  <th>Advertisement Img</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {data.map((advertise) => (
                  <tr>
                    <td>{advertise.Position}</td>
                    <td>
                      <img
                        src={`https://skillbackend.educationesupport.xyz//Upload/Images/${advertise.img}`}
                        alt="advertise"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </td>
                    <td>
                      <button
                        id="del-details"
                        style={{
                          width: "auto",
                          padding: "8px",
                          backgroundColor: "red",
                          color: "white",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          axios
                            .delete(
                              `https://skillbackend.educationesupport.xyz//advertisement/delete/${advertise.id}`
                            )
                            .then((res) => {
                              console.log(res.data);
                              // display success message using SweetAlert2
                              Swal.fire({
                                icon: "success",
                                title: "Success!",
                                text: "Data Deleted successfully!",
                              });
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertisementManage;

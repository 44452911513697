import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/courses.css";
import SecondNav from "../components/SecondNav";
import Footer from "../components/Footer";
import axios from "axios";

const Applications = () => {
  // const [isCategoriesExpanded, setCategoriesExpanded] = useState(false);
  // const [isLevelsExpanded, setLevelsExpanded] = useState(false);
  // const [isClassExpanded, setClassExpanded] = useState(false);
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [search, setSearch] = useState("");
  // const [filters, setFilters] = useState({
  //     classType: '',
  //     subject: '',
  //     level: ''
  // });
  const [displayedApplicationsCount, setDisplayedApplicationsCount] =
    useState(4);

  const navigate = useNavigate();

  // const toggleCategories = () => setCategoriesExpanded(!isCategoriesExpanded);
  // const toggleLevels = () => setLevelsExpanded(!isLevelsExpanded);
  // const toggleClass = () => setClassExpanded(!isClassExpanded);

  const fetchApplications = async () => {
    try {
      //   const query = new URLSearchParams(filters).toString();
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz//applications/all`
      );
      if (response.ok) {
        const data = await response.json();
        setApplications(data);
        setFilteredApplications(response.data);
      } else {
        console.error("Error fetching tuitions:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching tuitions:", error);
    }
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  useEffect(() => {
    if (search.trim() === "") {
      setFilteredApplications(applications);
    } else {
      setFilteredApplications(
        applications.filter(
          (application) =>
            application.appName &&
            typeof application.appName === "string" &&
            application.appName.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, applications]);

  // useEffect(() => {
  //     fetchApplications();
  //   }, [filters]);

  const handleApplicationClick = (id) => {
    navigate(`/applicationview/${id}`);
  };

  //   const handleFilterChange = (type, value) => {
  //     setFilters((prevFilters) => ({
  //       ...prevFilters,
  //       [type]: value
  //     }));
  //   };

  const handleSeeAllApplications = () => {
    setDisplayedApplicationsCount((prevCount) => prevCount + 4);
  };

  const applicationsToShow = filteredApplications.slice(
    0,
    displayedApplicationsCount
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    // Reset to original tuitions if no search term
    if (search) {
      const filteredApplications = applications.filter((application) =>
        application.appName.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredApplications(filteredApplications);
    } else {
      fetchApplications();
    }
  };

  return (
    <div>
      <SecondNav />
      <div className="Container">
        <div className="left-column">
          <div className="containerBoxOne-course">
            <div className="content-warp">
              <h4>FIND YOUR APPLICATION</h4>
              <form id="formInstitutes" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Enter Course Name"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <input type="submit" value="Search" />
              </form>
            </div>
          </div>
          {/* <div className={`Institution-section ${isClassExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleClass}>
                            Class Type <span className="expand-arrow">{isClassExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isClassExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-government" value="Individual" onClick={() => handleFilterChange('classType', 'Individual')} /></li>
                            <li><input type="button" className="button-private" value="Group" onClick={() => handleFilterChange('classType', 'Group')} /></li>
                            <li><input type="button" className="button-phd" value="Hall" onClick={() => handleFilterChange('classType', 'Hall')} /></li> 
                            <li><input type="button" className="button-hnd" value="All" onClick={() => handleFilterChange('classType', 'All')} /></li> 
                            <li><input type="button" className="button-postgraduate-diploma" value="Other" onClick={() => handleFilterChange('classType', 'Other')} /></li> 
                        </ul>
                        <hr />
                    </div> */}
          {/* <div className={`Categories-section ${isCategoriesExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleCategories}>
                            Subjects <span className="expand-arrow">{isCategoriesExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isCategoriesExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-business" value="English" onClick={() => handleFilterChange('subject', 'English')} /></li>
                            <li><input type="button" className="button-science" value="Science" onClick={() => handleFilterChange('subject', 'Science')} /></li>
                            <li><input type="button" className="button-engineering" value="Sinhala" onClick={() => handleFilterChange('subject', 'Sinhala')} /></li>
                            <li><input type="button" className="button-arts" value="Arts" onClick={() => handleFilterChange('subject', 'Arts')} /></li>
                            <li><input type="button" className="button-IT" value="IT" onClick={() => handleFilterChange('subject', 'IT')} /></li>
                        </ul>
                        <hr />
                    </div> */}
          {/* <div className={`Levels-section ${isLevelsExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleLevels}>
                            Level <span className="expand-arrow">{isLevelsExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isLevelsExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-training" value="A/L (Local)" onClick={() => handleFilterChange('classLevel', 'A/L (Local)')} /></li>
                            <li><input type="button" className="button-certificate" value="O/L (Local)" onClick={() => handleFilterChange('classLevel', 'O/L (Local)')} /></li>
                            <li><input type="button" className="button-diploma" value="A/L (London)" onClick={() => handleFilterChange('classLevel', 'A/L (London)')} /></li>
                            <li><input type="button" className="button-advanced-diploma" value="O/L (London)" onClick={() => handleFilterChange('classLevel', 'O/L (London)')} /></li>
                            <li><input type="button" className="button-bachelor" value="Grade 5" onClick={() => handleFilterChange('classLevel', 'Grade 5')} /></li>
                            <li><input type="button" className="button-degree" value="Degree" /></li>
                            <li><input type="button" className="button-bachelor" value="Bachelor" /></li>
                            <li><input type="button" className="button-postgraduate-diploma" value="Postgraduate Diploma" /></li>
                            <li><input type="button" className="button-masters" value="Masters" /></li>
                            <li><input type="button" className="button-phd" value="PhD" /></li>
                        </ul>
                    </div> */}
        </div>

        <div className="right-column">
          {applicationsToShow.map((application) => (
            <div
              className="Course"
              key={application.id}
              onClick={() => handleApplicationClick(applications.id)}
            >
              {/* <div className="calender">
                            <div className='Date-top'></div>
                            <div className="Date">{new Date(application.expire_date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</div>
                        </div> */}
              <div className="Course-info">
                <a href={`/applicationview/${application.id}`}>
                  <strong>{application.appName}</strong>
                </a>
                <p>Contact: {application.contact}</p>
                <p>
                  Due Date & Time:
                  {new Date(application.expire_time)
                    .toLocaleString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })
                    .replace(",", " -")}
                </p>
                <hr />
                <p1>
                  By :{" "}
                  <a href="#">
                    <strong>{application.ownerName}</strong>
                  </a>
                </p1>
              </div>
            </div>
          ))}
          {displayedApplicationsCount < applications.length && (
            <button
              className="more-applications"
              onClick={handleSeeAllApplications}
            >
              SEE MORE TUITIONS
            </button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Applications;

import React, { useState, useEffect } from "react";
import "../assets/css/courses.css";
import SecondNav from "../components/SecondNav";
import Footer from "../components/Footer";
import axios from "axios";

const AllLocalJobs = () => {
  const [isLevelsExpanded, setLevelsExpanded] = useState(false);
  const [ljobs, setljobs] = useState([]);
  const [displayedfjobsCount, setDisplayedfjobsCount] = useState(4);
  const [search, setSearch] = useState(""); // Add search state

  const [filters, setFilters] = useState({
    jobtype: "",
    joblevel: "",
  });

  const toggleLevels = () => setLevelsExpanded(!isLevelsExpanded);

  useEffect(() => {
    const fetchljobs = async () => {
      try {
        const queryParams = new URLSearchParams({
          jobtype: filters.jobtype || "",
          joblevel: filters.joblevel || "",
        }).toString();

        const response = await fetch(
          `https://skillbackend.educationesupport.xyz//ljobs/getalljobs?${queryParams}`
        );
        if (response.ok) {
          const data = await response.json();
          setljobs(data);
        } else {
          console.error("Failed to fetch jobs");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchljobs();
  }, [filters]);

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
  };

  const filteredJobs = ljobs.filter((job) => {
    const matchesType = filters.jobtype
      ? job.jobtype === filters.jobtype
      : true;
    const matchesJobLevel = filters.joblevel
      ? job.joblevel === filters.joblevel
      : true;
    const matchesSearch = job.jobtit
      .toLowerCase()
      .includes(search.toLowerCase()); // Add search filter
    return matchesType && matchesJobLevel && matchesSearch;
  });

  const fjobsToShow = filteredJobs.slice(0, displayedfjobsCount);

  const handleSeeAllfjobs = () => {
    setDisplayedfjobsCount((prevCount) => prevCount + 4);
  };

  const [options, setOptions] = useState([]);

  const getAll = () => {
    axios
      .get("https://skillbackend.educationesupport.xyz//ljobcate/get")
      .then((response) => {
        console.log(response.data);
        setOptions(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getAll();
  }, []);

  const fixedColors = [
    "#ff6347", // Tomato
    "#4682b4", // SteelBlue
    "#32cd32", // LimeGreen
    "#ffa500", // Orange
    "#8a2be2", // BlueViolet
    "#ffd700", // Gold
    "#ff1493", // DeepPink
    "#00bfff", // DeepSkyBlue
    "#ff4500", // OrangeRed
    "#6a5acd", // SlateBlue
    "#7fffd4", // Aquamarine
    "#ff69b4", // HotPink
    "#adff2f", // GreenYellow
    "#ffb6c1", // LightPink
    "#ff8c00", // DarkOrange
    "#6b8e23", // OliveDrab
    "#4682b4", // SteelBlue
    "#f08080", // LightCoral
    "#ff7f50", // Coral
    "#40e0d0", // Turquoise
    "#dc143c", // Crimson
    "#d2691e", // Chocolate
    "#cd5c5c", // IndianRed
    "#f0e68c", // Khaki
    "#7b68ee", // MediumSlateBlue
    "#9acd32", // YellowGreen
    "#8b4513", // SaddleBrown
    "#ffdab9", // PeachPuff
    "#b22222", // FireBrick
    "#c71585", // MediumVioletRed
    "#00fa9a", // MediumSpringGreen
    "#32cd32", // LimeGreen
    "#6a5acd", // SlateBlue
    "#ff4500", // OrangeRed
    "#ff8c00", // DarkOrange
    "#6495ed", // CornflowerBlue
    "#e9967a", // DarkSalmon
    "#9932cc", // DarkOrchid
    "#ffd700", // Gold
    "#f5deb3", // Wheat
    "#ffdead", // NavajoWhite
    "#c0c0c0", // Silver
    "#708090", // SlateGray
    "#2e8b57", // SeaGreen
    "#7f00ff", // Violet
    "#ff1493", // DeepPink
    "#d8bfd8", // Thistle
    "#ffd700", // Gold
    "#dc143c", // Crimson
    "#ffe4e1", // MistyRose
    "#00bfff", // DeepSkyBlue
    "#4169e1", // RoyalBlue
    "#ff69b4", // HotPink
    "#afeeee", // PaleTurquoise
    "#98fb98", // PaleGreen
    "#4682b4", // SteelBlue
  ];

  // Function to handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <SecondNav />
      <div className="Container">
        <div className="left-column">
          <div className="containerBoxOne-course">
            <div className="content-warp">
              <h4>FIND YOUR LOCAL JOB</h4>
              <form id="formInstitutes" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Enter Course Name"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <input type="submit" value="Search" />
              </form>
            </div>
          </div>

          <div
            style={{ margin: "10px 0px " }}
            className={`Categories-section ${isLevelsExpanded ? "active" : ""}`}
          >
            <h3 onClick={toggleLevels}>
              JOB TYPE{" "}
              <span className="expand-arrow">
                {isLevelsExpanded ? "▲" : "▼"}
              </span>
            </h3>
            <ul className={`filter-list ${isLevelsExpanded ? "active" : ""}`}>
              {options.map((option, index) => {
                return (
                  <li key={option.name}>
                    <input
                      style={{
                        width: "100%",
                        backgroundColor:
                          fixedColors[index % fixedColors.length],
                      }} // Use fixed colors
                      type="button"
                      className=""
                      value={option.name}
                      onClick={() => handleFilterChange("jobtype", option.name)}
                    />
                  </li>
                );
              })}
            </ul>
          </div>

          <div
            className={`Categories-section ${isLevelsExpanded ? "active" : ""}`}
          >
            <h3 onClick={toggleLevels}>
              JOB LEVEL{" "}
              <span className="expand-arrow">
                {isLevelsExpanded ? "▲" : "▼"}
              </span>
            </h3>
            <ul className={`filter-list ${isLevelsExpanded ? "active" : ""}`}>
              <li>
                <input
                  type="button"
                  style={{ width: "100%" }}
                  className="button-IT"
                  value="Entry-Level"
                  onClick={() => handleFilterChange("joblevel", "entry")}
                />
              </li>
              <li>
                <input
                  type="button"
                  style={{ width: "100%" }}
                  className="button-hnd"
                  value="Mid-Level"
                  onClick={() => handleFilterChange("joblevel", "mid")}
                />
              </li>
              <li>
                <input
                  type="button"
                  style={{ width: "100%" }}
                  className="button-business"
                  value="Senior-Level"
                  onClick={() => handleFilterChange("joblevel", "senior")}
                />
              </li>
            </ul>
          </div>
        </div>

        <div className="right-column">
          {fjobsToShow.map((ljobs) => (
            <div className="Course" key={ljobs.id}>
              <div className="calender">
                <div className="Date-top"></div>
                <div className="Date">
                  {new Date(ljobs.cdate).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                  })}
                </div>
              </div>
              <div className="Course-info">
                <a href={`/localjview/${ljobs.id}`}>
                  <strong>{ljobs.jobtit}</strong>
                </a>
                <p>
                  Post Date: {new Date(ljobs.pdate).toLocaleDateString("en-CA")}
                </p>
                <p>Type: {ljobs.jobtype}</p>
                <p>Level: {ljobs.joblevel}</p>
                <hr />
                <p1>
                  Company:{" "}
                  <a href="#">
                    <strong>{ljobs.cname}</strong>
                  </a>
                </p1>
              </div>
            </div>
          ))}
          {displayedfjobsCount < ljobs.length && (
            <button className="more-courses" onClick={handleSeeAllfjobs}>
              SEE MORE JOBS
            </button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllLocalJobs;

import React, { useEffect, useRef, useState } from "react";
import "../assets/css/institute-register.css";
import intlTelInput from "intl-tel-input";
import utilsScript from "intl-tel-input/build/js/utils";
import "intl-tel-input/build/css/intlTelInput.css"; // Assuming CSS import is necessary
import Swal from "sweetalert2";
import Footer from "../components/Footer";
import axios from "axios";
import uniqid from "uniqid";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import SecondNav from "../components/SecondNav";

const InstituteRegister = () => {
  const inputRef = useRef(null); //ref object for institute phone number

  useEffect(() => {
    if (inputRef.current) {
      intlTelInput(inputRef.current, {
        initialCountry: "",
        separateDialCode: true,
        utilsScript: utilsScript,
      });
    }
  }, []);

  //Institute Register form
  const [formData1, setFormData] = useState({
    inname: "",
    inWebsite: "",
    inEmail: "",
    inPhone: "",
    inaddress: "",
    inlocation: "",
    inlogo: "",
    indescription: "",
    ireg: "",
    pname: "",
    pconatct: "",
    inscountry: "",
    inType: "",
  });

  const [country, countrySet] = useState("");

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]:
        e.target.name === "inlogo" ? e.target.files[0] : e.target.value,
    }));
  };

  //getDate Function

  function getDate() {
    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (today = mm + "-" + dd + "-" + yyyy);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData1, country);

    const formdata = new FormData();

    formdata.append("id", uniqid()); //unique id
    formdata.append("in_name", formData1.inname);
    formdata.append("website", formData1.inWebsite);
    formdata.append("email", formData1.inEmail);
    formdata.append("tel", formData1.inPhone);
    formdata.append("address", formData1.inaddress);
    formdata.append("img", formData1.inlogo);
    formdata.append("desc", formData1.indescription);
    formdata.append("approve", "pending"); //sample values
    formdata.append("password", uniqid("password_")); //sample values uniqid('password_')
    formdata.append("deleted", "false"); //sample values
    formdata.append("token", formData1.inlocation); //sample values
    formdata.append("date", getDate()); //sample values
    formdata.append("ireg", formData1.ireg);
    formdata.append("pname", formData1.pname);
    formdata.append("pconatct", formData1.pconatct);
    formdata.append("country", country);
    formdata.append("type", formData1.inType);

    console.log(formdata);

    axios
      .post(
        "https://skillbackend.educationesupport.xyz//ins/register",
        formdata
      )
      .then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Successfully registered",
          text: "After approval we will notify you with login credentials via email",
          showConfirmButton: false,
          timer: 2500,
          customClass: {
            title: "custom-font", // Apply custom font to the title
            content: "custom-font", // Apply custom font to the text
          },
        }).then(() => {
          window.location.href = "/";
        });
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: {
            title: "custom-font", // Apply custom font to the title
            content: "custom-font", // Apply custom font to the text
          },
        });
      });
  };

  return (
    <div>
      <div className="instituteregister">
        <SecondNav />

        <div className="head-content-text">
          <h4>Institute Register</h4>
        </div>

        <div className="form-content">
          <form onSubmit={handleSubmit}>
            <div className="form-group-1">
              <i class="fa-solid fa-building-columns"></i>
              <input
                type="text"
                placeholder="Enter Institute Name"
                name="inname"
                id="name"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-registered"></i>
              <input
                type="text"
                placeholder="Enter Institute Register Number"
                name="ireg"
                id="name"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1">
              <i className="fas fa-university"></i>
              <select id="universityType" name="inType" onChange={handleChange}>
                <option value="" disabled selected>
                  Select University / Institute Type
                </option>
                <option value="Government">Government</option>
                <option value="Private">Private</option>
              </select>
            </div>

            <div className="form-group-1">
              <i class="fas fa-flag"></i>
              <span id="span-form">
                <CountryDropdown
                  name="inscountry"
                  className="CountryDropdown"
                  handleChange={(e) => countrySet(e.target.value)}
                ></CountryDropdown>
              </span>
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-globe"></i>
              <input
                type="url"
                placeholder="Enter Institute Website"
                name="inWebsite"
                id="Website"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-envelope"></i>
              <input
                type="Email"
                placeholder="Enter Institute Email"
                name="inEmail"
                id="Email"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1" id="form-con">
              <i class="fa-solid fa-phone"></i>
              <input
                type="tel"
                id="mobile_code"
                defaultValue=""
                name="inPhone"
                placeholder="Enter Institute Phone Number"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-address-book"></i>
              <input
                type="address"
                placeholder="Enter Institute Address"
                name="inaddress"
                id="address"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-location-dot"></i>
              <input
                type="url"
                placeholder="Enter Institute location"
                name="inlocation"
                id="location"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1">
              <label for="file-input" class="drop-container">
                <span class="drop-title">Drop the Institute logo</span>
                <input
                  type="file"
                  accept="image/*"
                  required=""
                  id="file-input"
                  name="inlogo"
                  onChange={handleChange}
                />
              </label>
            </div>

            <div className="form-group-1 textarea">
              <textarea
                name="indescription"
                id=""
                placeholder="Enter the description of the Institute background........ "
                onChange={handleChange}
              ></textarea>
            </div>

            <div className="form-group-1" style={{ padding: "5px" }}>
              <h2>Contact Person</h2>
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-user"></i>
              <input
                type="text"
                placeholder="Person Name"
                name="pname"
                id="location"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1">
              <i class="fa-solid fa-tty"></i>
              <input
                type="text"
                placeholder="Person contact No"
                name="pconatct"
                id="location"
                onChange={handleChange}
              />
            </div>

            <div className="form-group-1">
              <input
                type="submit"
                name="submit"
                value="Register"
                onChange={handleChange}
              />
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default InstituteRegister;

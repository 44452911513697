import React, { useState, useEffect } from "react";
import AdminDashboard from "./AdminDashboard";
import "../../assets/css/admin/allcourses.css";

const DeleteScholarships = () => {
  const [Scholarships, setScholarships] = useState([]);

  useEffect(() => {
    fetchDeletedScholarships();
  }, []);

  const fetchDeletedScholarships = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz//scholarships/deleted"
      );
      const data = await response.json();
      setScholarships(data);
    } catch (error) {
      console.error("Error fetching deleted Student Loans:", error);
    }
  };

  const handleRestore = async (ScholarshipsId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz//scholarships/restore/${ScholarshipsId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setScholarships(
          Scholarships.filter(
            (Scholarships) => Scholarships.id !== ScholarshipsId
          )
        );
        window.alert("Scholarship Restored");
      } else {
        console.error("Failed to restore Scholarship");
      }
    } catch (error) {
      console.error("Error restoring Scholarship:", error);
    }
  };

  const handlePermanentDelete = async (ScholarshipsId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz//scholarships/delete/permanent/${ScholarshipsId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setScholarships(
          Scholarships.filter(
            (Scholarships) => Scholarships.id !== ScholarshipsId
          )
        );
        window.alert("Scholarship delete permanently");
      } else {
        console.error("Failed to delete Scholarship permanently");
      }
    } catch (error) {
      console.error("Error deleting Scholarship permanently:", error);
    }
  };

  return (
    <div>
      <AdminDashboard />

      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>DELETE SCHOLARSHIPS</h1>
          <form>
            <input type="text" name="" id="" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>

        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>Scholarship Programme Name</th>
                  <th>Country</th>
                  <th>Offered By</th>
                  <th>Closing Date </th>
                  <th>Contact No.</th>
                  <th>Email</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Scholarships.map((Scholarships) => (
                  <tr key={Scholarships.id}>
                    <td>{Scholarships.sProgrammeName}</td>
                    <td>{Scholarships.country}</td>
                    <td>{Scholarships.university}</td>
                    <td>
                      {new Date(Scholarships.closingDate).toLocaleDateString(
                        "en-CA"
                      )}
                    </td>
                    <td>{Scholarships.institutePhone}</td>
                    <td>{Scholarships.instituteEmail}</td>
                    <td>
                      <a
                        href={Scholarships.instituteWebsite}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visit Website
                      </a>
                    </td>
                    <td id="action-buttons">
                      <a
                        href="#!"
                        id="restore-details"
                        onClick={() => handleRestore(Scholarships.id)}
                      >
                        RESTORE
                      </a>
                      <a
                        href="#!"
                        id="del-details"
                        onClick={() => handlePermanentDelete(Scholarships.id)}
                      >
                        DELETE
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteScholarships;

import React, { useEffect, useState } from "react";
import AdminDashboard from "./AdminDashboard";
import "../../assets/css/admin/InstituteAll.css";
import axios from "axios";
import "intl-tel-input/build/css/intlTelInput.css"; // Assuming CSS import is necessary
import Swal from "sweetalert2";

const InstituteAll = () => {
  const [institute, setInstitute] = useState([]);

  const getAllDate = () => {
    axios
      .get("https://skillbackend.educationesupport.xyz//ins/delete")
      .then((response) => {
        console.log(response.data);
        setInstitute(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllDate();
  }, []);

  const UpdateDelete = (e, id, email, password, name) => {
    e.preventDefault();
    console.log(id);

    const ddata = {
      deleted: "true",
    };

    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(
            "https://skillbackend.educationesupport.xyz//ins/delete/" + id,
            ddata
          )
          .then((response) => {
            console.log(response.data);
            getAllDate();
            const approve = "false";
            sendEmail(e, id, name, email, password, approve);
          })
          .catch((err) => {
            console.log(err);
          });

        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const UpdateApprove = (e, id, email, password, name) => {
    e.preventDefault();
    console.log(id);

    const ddata = {
      approve: "approve",
    };

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to approve this?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approved it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(
            "https://skillbackend.educationesupport.xyz//ins/approve/" + id,
            ddata
          )
          .then((response) => {
            console.log(response.data);
            getAllDate();
            UpdateFirstLog();
            const approve = "true";
            sendEmail(e, id, name, email, password, approve);
          })
          .catch((err) => {
            console.log(err);
          });
        Swal.fire({
          title: "Approved!",
          text: "Your file has been Approved.",
          icon: "success",
        });
      }
    });
  };

  const sendEmail = (e, id, name, email, password, approve) => {
    e.preventDefault();

    const data = {
      id: id,
      name: name,
      email: email,
      password: password,
      approve: approve,
    };

    axios
      .post("https://skillbackend.educationesupport.xyz//common/send", data)
      .then((response) => {
        Swal.fire({
          title: "Email Sent!",
          text: "Notified owner via Email.",
          icon: "success",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [newitem, setItem] = useState("new-item");
  const [newNO, serNO] = useState("new-no");

  function getDate() {
    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (today = mm + "-" + dd + "-" + yyyy);
  }

  const dateset = getDate();

  const sortedInstitutes = institute.sort((a, b) => {
    // Sort by date in descending order (most recent first)
    return new Date(b.date) - new Date(a.date);
  });

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const filteredInstitutes = sortedInstitutes.filter((institute) =>
    institute.in_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const UpdateFirstLog = () => {
    const data = {
      firstlog: "false",
    };

    axios
      .put(
        "https://skillbackend.educationesupport.xyz//ins/firstLogUpdate/" +
          sessionStorage.getItem("id"),
        data
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <AdminDashboard />

      <div className="AllViews-admin">
        <div className="search-icon-admin admin-institute-search">
          <h1>PENDING INSTITUTE</h1>
          <form>
            <input
              type="text"
              name=""
              onChange={handleSearch}
              id=""
              placeholder="Search Item"
            />
            <input type="submit" value="SEARCH" />
          </form>
        </div>

        <div className="scroller-box">
          <div className="table-institute">
            <table>
              <thead>
                <tr>
                  <th>Institute Name</th>
                  <th>Institute Type</th>
                  <th>Request Date</th>
                  <th>Contact Person name</th>
                  <th>Conatct Person No</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {filteredInstitutes.length > 0 ? (
                  filteredInstitutes.map((institute) => (
                    <tr key={institute.id}>
                      <td>
                        {institute.in_name}{" "}
                        {dateset === institute.date ? (
                          <span id={newitem}>New</span>
                        ) : (
                          <span id={newNO}></span>
                        )}
                      </td>
                      <td>{institute.type}</td>
                      <td>{institute.date}</td>
                      <td>{institute.pname}</td>
                      <td>{institute.pconatct}</td>
                      <td>
                        <button id="view-web">
                          <a href={`/institutesview/${institute.id}`}>
                            SITE VIEW
                          </a>
                        </button>
                      </td>
                      <td id="action-buttons">
                        <button
                          onClick={(e) => {
                            UpdateApprove(
                              e,
                              institute.id,
                              institute.email,
                              institute.password_on,
                              institute.in_name
                            );
                          }}
                          id="app-details"
                        >
                          APPROVE
                        </button>
                        <button
                          onClick={(e) => {
                            UpdateDelete(
                              e,
                              institute.id,
                              institute.email,
                              institute.password_on,
                              institute.in_name
                            );
                          }}
                          id="del-details"
                        >
                          DELETE
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      style={{ textAlign: "center", padding: "10px" }}
                    >
                      Search data not found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstituteAll;

import React, { useState, useEffect } from "react";
import AdminDashboard from "./AdminDashboard";
import "../../assets/css/admin/allcourses.css";

const AllProfExams = () => {
  const [professionalExams, setProfessionalExams] = useState([]);

  useEffect(() => {
    fetchProfessionalExams();
  }, []);

  const fetchProfessionalExams = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz//professionalExams/all"
      );
      const data = await response.json();
      setProfessionalExams(data);
    } catch (error) {
      console.error("Error fetching professional exams:", error);
    }
  };

  const handleDelete = async (examId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz//professionalExams/delete/${examId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setProfessionalExams(
          professionalExams.filter((exam) => exam.id !== examId)
        );
        window.alert("Professional Exam deleted");
      } else {
        console.error("Failed to delete professional exam");
      }
    } catch (error) {
      console.error("Error deleting professional exam:", error);
    }
  };

  return (
    <div>
      <AdminDashboard />
      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>ALL PROFESSIONAL EXAMS</h1>
          <form>
            <input type="text" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>
        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>Exam Name</th>
                  <th>Short Name</th>
                  <th>By</th>
                  <th>Start Date</th>
                  <th>Contact No.</th>
                  <th>Email</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {professionalExams.map((exam) => (
                  <tr key={exam.id}>
                    <td>{exam.examName}</td>
                    <td>{exam.shortName}</td>
                    <td>{exam.university}</td>
                    <td>
                      {new Date(exam.startDate).toLocaleDateString("en-CA")}
                    </td>
                    <td>{exam.institutePhone}</td>
                    <td>{exam.instituteEmail}</td>
                    <td>
                      <a
                        href={exam.instituteWebsite}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visit Website
                      </a>
                    </td>
                    <td id="action-buttons">
                      <a
                        href="#!"
                        id="del-details"
                        onClick={() => handleDelete(exam.id)}
                      >
                        DELETE
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProfExams;

import React, { useState, useEffect } from "react";
import InstituteDashboard from "./InstituteDashboard";

const ManageExternalDegree = () => {
  const [externalDegrees, setExternalDegrees] = useState([]);
  const [editingExternalDegreeId, setEditingExternalDegreeId] = useState(null);
  const [editedExternalDegree, setEditedExternalDegree] = useState({});

  const instituteId = sessionStorage.getItem("id");

  useEffect(() => {
    if (instituteId) {
      fetchExternalDegrees();
    }
  }, [instituteId]);

  const fetchExternalDegrees = async () => {
    const instituteId = sessionStorage.getItem("id");
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz//externalDegree/all?institute_id=${instituteId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setExternalDegrees(data);
    } catch (error) {
      console.error("Error fetching external degrees:", error);
    }
  };

  const handleDelete = async (externalDegreeId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz//externalDegree/delete/${externalDegreeId}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        setExternalDegrees(
          externalDegrees.filter(
            (externalDegree) => externalDegree.id !== externalDegreeId
          )
        );
      } else {
        console.error("Failed to delete external degree");
      }
    } catch (error) {
      console.error("Error deleting external degree:", error);
    }
  };

  const handleEdit = (externalDegree) => {
    setEditingExternalDegreeId(externalDegree.id);
    setEditedExternalDegree(externalDegree);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedExternalDegree({ ...editedExternalDegree, [name]: value });
  };

  const handleSave = async (externalDegreeId) => {
    try {
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz//externalDegree/update/${externalDegreeId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editedExternalDegree),
        }
      );
      if (response.ok) {
        setExternalDegrees(
          externalDegrees.map((externalDegree) =>
            externalDegree.id === externalDegreeId
              ? editedExternalDegree
              : externalDegree
          )
        );
        editingExternalDegreeId(null);
      } else {
        console.error("Failed to save external degree");
      }
    } catch (error) {
      console.error("Error saving external degree:", error);
    }
  };

  const handleCancel = () => {
    editingExternalDegreeId(null);
    setEditedExternalDegree({});
  };

  return (
    <div>
      <InstituteDashboard />
      <div className="AllViews-admin-course">
        <div className="search-icon-admin admin-course-search">
          <h1>MANAGE EXTERNAL DEGREES</h1>
          <form>
            <input type="text" name="" id="" placeholder="Search Item" />
            <input type="submit" value="SEARCH" />
          </form>
        </div>

        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>External Degree Name</th>
                  <th>Institute Name</th>
                  <th>Start Date</th>
                  <th>Duration</th>
                  <th>View</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {externalDegrees.map((externalDegree) => (
                  <tr key={externalDegree.id}>
                    <td>
                      {editingExternalDegreeId === externalDegree.id ? (
                        <input
                          type="text"
                          name="courseName"
                          value={editedExternalDegree.courseName}
                          onChange={handleChange}
                        />
                      ) : (
                        externalDegree.courseName
                      )}
                    </td>
                    <td>
                      {editingExternalDegreeId === externalDegree.id ? (
                        <input
                          type="text"
                          name="university"
                          value={editedExternalDegree.university}
                          onChange={handleChange}
                        />
                      ) : (
                        externalDegree.university
                      )}
                    </td>
                    <td>
                      {editingExternalDegreeId === externalDegree.id ? (
                        <input
                          type="date"
                          name="startDate"
                          value={editedExternalDegree.startDate}
                          onChange={handleChange}
                        />
                      ) : (
                        new Date(externalDegree.startDate).toLocaleDateString(
                          "en-CA"
                        )
                      )}
                    </td>
                    <td>
                      {editingExternalDegreeId === externalDegree.id ? (
                        <input
                          type="duration"
                          name="duration"
                          value={editedExternalDegree.duration}
                          onChange={handleChange}
                        />
                      ) : (
                        externalDegree.duration
                      )}
                    </td>
                    <td>
                      <a href="#!" id="view-web">
                        WEBSITE
                      </a>
                    </td>
                    <td id="action-buttons">
                      {editingExternalDegreeId === externalDegree.id ? (
                        <>
                          <a
                            href="#!"
                            id="man-details"
                            onClick={() => handleSave(externalDegree.id)}
                          >
                            SAVE
                          </a>
                          <a href="#!" id="del-details" onClick={handleCancel}>
                            CANCEL
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            href="#!"
                            id="man-details"
                            onClick={() => handleEdit(externalDegree)}
                          >
                            EDIT
                          </a>
                          <a
                            href="#!"
                            id="del-details"
                            onClick={() => handleDelete(externalDegree.id)}
                          >
                            DELETE
                          </a>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageExternalDegree;

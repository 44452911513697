import React, { useState, useEffect } from "react";
import axios from "axios";
import "../assets/css/instituteview.css";
import SecondNav from "../components/SecondNav";
import Footer from "../components/Footer";
import { useParams, useNavigate } from "react-router-dom";

const InternatinalSchlView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [internationalSchool, setSchool] = useState(null);

  useEffect(() => {
    const fetchSchool = async () => {
      try {
        const response = await axios.get(
          `https://skillbackend.educationesupport.xyz//internationalSchools/${id}`
        );
        setSchool(response.data);
      } catch (error) {
        console.error("Error fetching school data:", error);
      }
    };
    fetchSchool();
  }, [id]);

  if (!internationalSchool) return <p>Loading...</p>;

  const handleContactUs = () => {
    navigate("/contact");
  };

  return (
    <div className="institutesView">
      <SecondNav />

      <div className="inview">
        <div className="block-one-inview">
          <div className="heading-text-inview">
            <h3>{internationalSchool.intschlName}</h3>
            <hr id="hr-inview" />
          </div>

          <div className="img-inview">
            <img
              src={`https://skillbackend.educationesupport.xyz//Upload/images/intschls/${internationalSchool.logo}`}
              alt={internationalSchool.intschlName}
              width={300}
            />
          </div>

          <div className="content-inview">
            <p>{internationalSchool.description}</p>
          </div>
          <div className="branch-info">
            <h4>Branches</h4>
            {internationalSchool.branchestableRows.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>City</th>
                    <th>Address</th>
                    <th>Phone</th>
                    <th>Location</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {internationalSchool.branchestableRows.map((row, index) => (
                    <tr key={index}>
                      <td>{row.city}</td>
                      <td>{row.address}</td>
                      <td>{row.phone}</td>
                      <td>
                        {row.location ? (
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                              row.location
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Map
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>

                      <td>{row.email || "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No branch information available.</p>
            )}
          </div>

          <div className="map-section">
            <iframe
              src={internationalSchool.mainLocation}
              allowFullScreen
              title="Map"
            ></iframe>
          </div>
          <div className="inq">
            <h3>Send Inquiry</h3>
            <button type="cbutton" onClick={handleContactUs}>
              Contact Us
            </button>
          </div>
        </div>

        <div className="block-two-inview">
          <ul>
            <li>
              <strong>
                <i className="fa-solid fa-globe"></i> Website:{" "}
              </strong>{" "}
              <a
                href={internationalSchool.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {internationalSchool.website}
              </a>
            </li>
            <li>
              <strong>
                <i className="fa-solid fa-envelope"></i> Email:{" "}
              </strong>{" "}
              <p>{internationalSchool.commonEmail}</p>
            </li>
            <li>
              <strong>
                <i className="fa-solid fa-phone"></i> Contact:{" "}
              </strong>{" "}
              <p>{internationalSchool.commonPhone}</p>
            </li>
            <li>
              <strong>
                <i className="fa-solid fa-graduation-cap"></i> Study Level:{" "}
              </strong>{" "}
              <p>{internationalSchool.studyLevel}</p>
            </li>
            <li>
              <strong>
                <i className="fa-solid fa-building"></i> Facilities:{" "}
              </strong>{" "}
              <p>{internationalSchool.facilities}</p>
            </li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default InternatinalSchlView;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/courseView.css";
import ApplyForm from "../components/ApplyNow";
import SecondNav from "../components/SecondNav";
import Footer from "../components/Footer";
import axios from "axios";

const StudentLoanView = () => {
  const { id } = useParams();
  const [stdLoans, setStdLoans] = useState(null);

  useEffect(() => {
    const fetchStdLoansData = async () => {
      try {
        const response = await axios.get(
          `https://skillbackend.educationesupport.xyz//stdLoans/${id}`
        );
        console.log(response.data);
        setStdLoans(response.data);
      } catch (error) {
        console.error("Error fetching student loans data:", error);
      }
    };

    fetchStdLoansData();
  }, [id]);

  if (!stdLoans) {
    return null;
  }

  return (
    <div>
      <SecondNav />
      <div className="c_container">
        <div className="c_leftcolumn">
          <div className="programme">
            <h2>{stdLoans.degreeName}</h2>
            <hr id="hr-programme" />
            <div className="c-image">
              {stdLoans.logo && (
                <img
                  src={`https://skillbackend.educationesupport.xyz//Upload/images/loans/${stdLoans.logo}`}
                  alt={stdLoans.degreeName}
                />
              )}
            </div>
            <h3>Overview</h3>
            <p id="p-overview">
              {stdLoans.description || "No description available"}
            </p>
          </div>

          <div className="programmes">
            <h3>RELATED External Degrees</h3>
            <hr id="hr-programme" />
            <div className="courseitem">
              <div className="coursetitle">
                <a href="#">
                  <strong>Master Of Business Management</strong>
                </a>
              </div>
              <div className="coursedetails">Duration: 2 Years</div>
              <hr />
              <div className="courseins">
                By: <a href="#">{stdLoans.university}</a>
              </div>
            </div>
          </div>

          <div className="programme">
            <ApplyForm />
          </div>
        </div>

        <div className="c_rightcolumn">
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-university"></i>
                    <span>Institute:</span>
                  </div>
                </td>
                <td>
                  <p>{stdLoans.university}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-chalkboard-teacher"></i>
                    <span>Faculty:</span>
                  </div>
                </td>
                <td>
                  <p>{stdLoans.faculty}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fas fa-building"></i>
                    <span>Department:</span>
                  </div>
                </td>
                <td>
                  <p>{stdLoans.department}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-clock"></i>
                    <span>Duration:</span>
                  </div>
                </td>
                <td>
                  <p>{stdLoans.duration}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-calendar"></i>
                    <span>Start Date: </span> <td></td>
                  </div>
                </td>
                <td>
                  <p>
                    {new Date(stdLoans.startDate).toLocaleDateString("en-CA")}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-graduation-cap"></i>
                    <span>Level:</span>
                  </div>
                </td>
                <td>
                  <p>{stdLoans.level}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-folder"></i>
                    <span>Category:</span>
                  </div>
                </td>
                <td>
                  <p>{stdLoans.category}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-phone"></i>
                    <span>Contact:</span>
                  </div>
                </td>
                <td>
                  <p>{stdLoans.institutePhone}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-map-marker"></i>
                    <span>Location:</span>
                  </div>
                </td>
                <td>
                  <p>{stdLoans.instituteLocation}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="icon-text">
                    <i className="fa fa-money-bill-alt"></i>
                    <span>Course Fee:</span>
                  </div>
                </td>
                <td>
                  <p>
                    {stdLoans.courseFee || "Course fee details not available"}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudentLoanView;

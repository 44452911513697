import React, { useState, useEffect } from "react";
import "../assets/css/courses.css";
import SecondNav from "../components/SecondNav";
import Footer from "../components/Footer";
import axios from "axios";

const AllForeignJobs = () => {
  const [isCategoriesExpanded, setCategoriesExpanded] = useState(false);
  const [isLevelsExpanded, setLevelsExpanded] = useState(false);
  const [isCountryExpanded, setCountryExpanded] = useState(false);
  const [fjobs, setfjobs] = useState([]);
  const [displayedfjobsCount, setDisplayedfjobsCount] = useState(4);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    fType: "",
    country: "",
  });

  const toggleCategories = () => setCategoriesExpanded(!isCategoriesExpanded);
  const toggleLevels = () => setLevelsExpanded(!isLevelsExpanded);
  const toggleCountry = () => setCountryExpanded(!isCountryExpanded);

  useEffect(() => {
    const fetchfjobs = async () => {
      try {
        const queryParams = new URLSearchParams({
          fType: filters.fType || "",
          country: filters.country || "",
        }).toString();

        const response = await fetch(
          `https://skillbackend.educationesupport.xyz//fjobs/getall?${queryParams}`
        );
        if (response.ok) {
          const data = await response.json();
          setfjobs(data);
        } else {
          console.error("Failed to fetch jobs");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchfjobs();
  }, [filters]);

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
  };

  const filteredJobs = fjobs.filter((job) => {
    const matchesType = filters.fType ? job.fType === filters.fType : true;
    const matchesCountry = filters.country
      ? job.country === filters.country
      : true;
    const matchesSearch = job.jobtit
      .toLowerCase()
      .includes(search.toLowerCase());
    return matchesType && matchesCountry && matchesSearch;
  });

  const fjobsToShow = filteredJobs.slice(0, displayedfjobsCount);

  const handleSeeAllfjobs = () => {
    setDisplayedfjobsCount((prevCount) => prevCount + 4);
  };

  // Options
  const [options, setOptions] = useState([]);

  const getAll = () => {
    axios
      .get("https://skillbackend.educationesupport.xyz//fjobcate/getAll")
      .then((response) => {
        console.log(response.data);
        setOptions(response.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAll();
  }, []);

  const fixedColors = [
    "#ff6347", // Tomato
    "#4682b4", // SteelBlue
    "#32cd32", // LimeGreen
    "#ffa500", // Orange
    "#8a2be2", // BlueViolet
    "#ffd700", // Gold
    "#ff1493", // DeepPink
    "#00bfff", // DeepSkyBlue
    "#ff4500", // OrangeRed
    "#6a5acd", // SlateBlue
    "#7fffd4", // Aquamarine
    "#ff69b4", // HotPink
    "#adff2f", // GreenYellow
    "#ffb6c1", // LightPink
    "#ff8c00", // DarkOrange
    "#6b8e23", // OliveDrab
    "#4682b4", // SteelBlue
    "#f08080", // LightCoral
    "#ff7f50", // Coral
    "#40e0d0", // Turquoise
    "#dc143c", // Crimson
    "#d2691e", // Chocolate
    "#cd5c5c", // IndianRed
    "#f0e68c", // Khaki
    "#7b68ee", // MediumSlateBlue
    "#9acd32", // YellowGreen
    "#8b4513", // SaddleBrown
    "#ffdab9", // PeachPuff
    "#b22222", // FireBrick
    "#c71585", // MediumVioletRed
    "#00fa9a", // MediumSpringGreen
    "#32cd32", // LimeGreen
    "#6a5acd", // SlateBlue
    "#ff4500", // OrangeRed
    "#ff8c00", // DarkOrange
    "#6495ed", // CornflowerBlue
    "#e9967a", // DarkSalmon
    "#9932cc", // DarkOrchid
    "#ffd700", // Gold
    "#f5deb3", // Wheat
    "#ffdead", // NavajoWhite
    "#c0c0c0", // Silver
    "#708090", // SlateGray
    "#2e8b57", // SeaGreen
    "#7f00ff", // Violet
    "#ff1493", // DeepPink
    "#d8bfd8", // Thistle
    "#ffd700", // Gold
    "#dc143c", // Crimson
    "#ffe4e1", // MistyRose
    "#00bfff", // DeepSkyBlue
    "#4169e1", // RoyalBlue
    "#ff69b4", // HotPink
    "#afeeee", // PaleTurquoise
    "#98fb98", // PaleGreen
    "#4682b4", // SteelBlue
  ];

  // Function to handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <SecondNav />
      <div className="Container">
        <div className="left-column">
          <div className="containerBoxOne-course">
            <div className="content-warp">
              <h4>FIND YOUR LOCAL JOB</h4>
              <form id="formInstitutes" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Enter Course Name"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <input type="submit" value="Search" />
              </form>
            </div>
          </div>
          <div
            style={{ margin: "10px 0px " }}
            className={`Categories-section ${
              isCategoriesExpanded ? "active" : ""
            }`}
          >
            <h3
              onClick={toggleCategories}
              style={{ textTransform: "uppercase" }}
            >
              Category{" "}
              <span className="expand-arrow">
                {isCategoriesExpanded ? "▲" : "▼"}
              </span>
            </h3>
            <ul
              className={`filter-list ${isCategoriesExpanded ? "active" : ""}`}
            >
              {options.map((option, index) => {
                return (
                  <li key={option.name}>
                    <input
                      style={{
                        width: "80%",
                        backgroundColor:
                          fixedColors[index % fixedColors.length],
                      }} // Use fixed colors
                      type="button"
                      className=""
                      value={option.name}
                      onClick={() => handleFilterChange("fType", option.name)}
                    />
                  </li>
                );
              })}
            </ul>
            <hr />
          </div>
          {/* <div className={`Categories-section ${isLevelsExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleLevels}>
                            Job Type <span className="expand-arrow">{isLevelsExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isLevelsExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-foundation" value="Full-Time" onClick={() => handleFilterChange('fType', 'Full-Time')} /></li>
                            <li><input type="button" className="button-certificate" value="Part-Time" onClick={() => handleFilterChange('fType', 'Part-Time')} /></li>
                            <li><input type="button" className="button-degree" value="Internship" onClick={() => handleFilterChange('fType', 'Internship')} /></li>
                            <li><input type="button" className="button-diploma" value="Contract" onClick={() => handleFilterChange('fType', 'Contract')} /></li>
                            <li><input type="button" className="button-masters" value="Temporary" onClick={() => handleFilterChange('fType', 'Temporary')} /></li>
                        </ul>
                    </div> */}

          <div
            className={`Categories-section ${
              isCountryExpanded ? "active" : ""
            }`}
          >
            <h3 onClick={toggleCountry}>
              COUNTRY{" "}
              <span className="expand-arrow">
                {isCountryExpanded ? "▲" : "▼"}
              </span>
            </h3>
            <ul className={`filter-list ${isCountryExpanded ? "active" : ""}`}>
              <li>
                <input
                  style={{ width: "80%" }}
                  type="button"
                  className="buttonAustralia"
                  value="Australia"
                  onClick={() => handleFilterChange("country", "Australia")}
                />
              </li>
              <li>
                <input
                  style={{ width: "80%" }}
                  type="button"
                  className="buttonCanada"
                  value="Canada"
                  onClick={() => handleFilterChange("country", "Canada")}
                />
              </li>
              <li>
                <input
                  style={{ width: "80%" }}
                  type="button"
                  className="buttonChina"
                  value="China"
                  onClick={() => handleFilterChange("country", "China")}
                />
              </li>
              <li>
                <input
                  style={{ width: "80%" }}
                  type="button"
                  className="buttonFrance"
                  value="France"
                  onClick={() => handleFilterChange("country", "France")}
                />
              </li>
              <li>
                <input
                  style={{ width: "80%" }}
                  type="button"
                  className="buttonGermany"
                  value="Germany"
                  onClick={() => handleFilterChange("country", "Germany")}
                />
              </li>
              <li>
                <input
                  style={{ width: "80%" }}
                  type="button"
                  className="buttonIndia"
                  value="India"
                  onClick={() => handleFilterChange("country", "India")}
                />
              </li>
              <li>
                <input
                  style={{ width: "80%" }}
                  type="button"
                  className="buttonJapan"
                  value="Japan"
                  onClick={() => handleFilterChange("country", "Japan")}
                />
              </li>
              <li>
                <input
                  style={{ width: "80%" }}
                  type="button"
                  className="buttonUK"
                  value="UK"
                  onClick={() => handleFilterChange("country", "UK")}
                />
              </li>
              <li>
                <input
                  style={{ width: "80%" }}
                  type="button"
                  className="buttonUSA"
                  value="USA"
                  onClick={() => handleFilterChange("country", "USA")}
                />
              </li>
            </ul>
            <hr />
          </div>
        </div>

        <div className="right-column">
          {fjobsToShow.map((fjobs) => (
            <div className="Course" key={fjobs.id}>
              <div className="calender">
                <div className="Date-top"></div>
                <div className="Date">
                  {new Date(fjobs.cdate).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                  })}
                </div>
              </div>
              <div className="Course-info">
                <a href={`/fjob/${fjobs.id}`}>
                  <strong>{fjobs.jobtit}</strong>
                </a>
                <p>
                  Post Date: {new Date(fjobs.pdate).toLocaleDateString("en-CA")}
                </p>
                <p>Type: {fjobs.fType}</p>
                <p>Category: {fjobs.fType}</p>
                <p>Country: {fjobs.country}</p>
                <hr />
                <p1>
                  Company:{" "}
                  <a href="!#">
                    <strong>{fjobs.aname}</strong>
                  </a>
                </p1>
              </div>
            </div>
          ))}
          {displayedfjobsCount < fjobs.length && (
            <button className="more-courses" onClick={handleSeeAllfjobs}>
              SEE MORE FOREIGN JOBS
            </button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllForeignJobs;

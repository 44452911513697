import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/courses.css";
import SecondNav from "../components/SecondNav";
import Footer from "../components/Footer";
import axios from "axios";

const StudentLoans = () => {
  const [isCategoriesExpanded, setCategoriesExpanded] = useState(false);
  const [isLevelsExpanded, setLevelsExpanded] = useState(false);
  const [isUniExpanded, setUniExpanded] = useState(false);
  const [stdLoans, setStdLoans] = useState([]);
  const [filteredLoans, setFilteredLoans] = useState([]); // New state for filtered loans
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]);
  const [filters, setFilters] = useState({
    universityType: "",
    category: "",
    level: "",
  });
  const [search, setSearch] = useState(""); // New search state
  const [displayedLoansCount, setDisplayedLoansCount] = useState(4);

  const navigate = useNavigate();

  const toggleCategories = () => setCategoriesExpanded(!isCategoriesExpanded);
  const toggleLevels = () => setLevelsExpanded(!isLevelsExpanded);
  const toggleUni = () => setUniExpanded(!isUniExpanded);

  const fetchStdLoans = async () => {
    try {
      const query = new URLSearchParams(filters).toString();
      const response = await fetch(
        `https://skillbackend.educationesupport.xyz//stdLoans/all?${query}`
      );
      if (response.ok) {
        const data = await response.json();
        setStdLoans(data);
        setFilteredLoans(data); // Set filtered loans initially
      } else {
        console.error("Error fetching student loans:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching student loans:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz//category/all"
      );
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      } else {
        console.error("Error fetching categories:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchLevels = async () => {
    try {
      const response = await fetch(
        "https://skillbackend.educationesupport.xyz//level/all"
      );
      if (response.ok) {
        const data = await response.json();
        setLevels(data);
      } else {
        console.error("Error fetching levels:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching levels:", error);
    }
  };

  useEffect(() => {
    fetchStdLoans();
    fetchCategories();
    fetchLevels();
  }, [filters]);

  // Update filtered loans based on search query
  useEffect(() => {
    if (search.trim() === "") {
      setFilteredLoans(stdLoans);
    } else {
      setFilteredLoans(
        stdLoans.filter(
          (loan) =>
            loan.degreeName &&
            loan.degreeName.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search, stdLoans]);

  const handleLoanClick = (id) => {
    navigate(`/stdloanview/${id}`);
  };

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
  };

  const handleSeeAllLoans = () => {
    setDisplayedLoansCount((prevCount) => prevCount + 4);
  };

  const stdLoansToShow = filteredLoans.slice(0, displayedLoansCount);

  // Define an array of fixed colors
  const fixedColors = [
    "#ff6347",
    "#4682b4",
    "#32cd32",
    "#ffa500",
    "#8a2be2",
    "#3a5acd",
    "#ff1493",
    "#00bfff",
    "#ff4500",
    "#6a5acd",
    "#ff6347",
    "#ff69b4",
    "#36d85c",
    "#ffa6c0",
    "#ff8c00",
    "#6b8e23",
    "#4682b4",
    "#ff1493",
    "#ff7f50",
    "#17a2b8",
    "#dc143c",
    "#d2691e",
    "#cd5c5c",
    "#00e68c",
    "#7b68ee",
    "#9acd32",
    "#8b4513",
    "#5fdab9",
    "#b22222",
    "#c71585",
    "#4169e1",
    "#32cd32",
    "#6a5acd",
    "#ff4500",
    "#ff8c00",
    "#6495ed",
    "#e9967a",
    "#9932cc",
    "#ffd700",
    "#f5deb3",
    "#ffdead",
    "#c0c0c0",
    "#708090",
    "#2e8b57",
    "#7f00ff",
    "#ff1493",
    "#d8bfd8",
    "#ffd700",
    "#dc143c",
    "#ffe4e1",
    "#00bfff",
    "#4169e1",
    "#ff69b4",
    "#afeeee",
    "#98fb98",
    "#4682b4",
  ];

  // Function to handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <SecondNav />
      <div className="Container">
        <div className="left-column">
          <div className="containerBoxOne-course">
            <div className="content-warp">
              <h4>FIND YOUR LOAN</h4>
              <form id="formInstitutes" onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Enter Course Name"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <input type="submit" value="Search" />
              </form>
            </div>
          </div>

          <div
            className={`Categories-section ${
              isCategoriesExpanded ? "active" : ""
            }`}
          >
            <h3 onClick={toggleCategories}>
              CATEGORIES{" "}
              <span className="expand-arrow">
                {isCategoriesExpanded ? "▲" : "▼"}
              </span>
            </h3>
            <ul
              className={`filterlist ${isCategoriesExpanded ? "active" : ""}`}
            >
              {categories.map((category, index) => (
                <li key={category.id}>
                  <input
                    type="button"
                    style={{
                      backgroundColor: fixedColors[index % fixedColors.length],
                    }} // Use fixed colors
                    value={category.category}
                    onClick={() =>
                      handleFilterChange("category", category.category)
                    }
                  />
                </li>
              ))}
            </ul>
            <hr />
          </div>
          <div className={`Levels-section ${isLevelsExpanded ? "active" : ""}`}>
            <h3 onClick={toggleLevels}>
              LEVELS{" "}
              <span className="expand-arrow">
                {isLevelsExpanded ? "▲" : "▼"}
              </span>
            </h3>
            <ul className={`filter-list ${isLevelsExpanded ? "active" : ""}`}>
              {levels.map((level, index) => (
                <li key={level.id}>
                  <input
                    type="button"
                    style={{
                      backgroundColor: fixedColors[index % fixedColors.length],
                    }}
                    value={level.level}
                    onClick={() => handleFilterChange("level", level.level)}
                  />
                </li>
              ))}
            </ul>
            <hr />
          </div>
          <div className={`Uni-section ${isUniExpanded ? "active" : ""}`}>
            <h3 onClick={toggleUni}>
              UNIVERSITY / INSTITUTE{" "}
              <span className="expand-arrow">{isUniExpanded ? "▲" : "▼"}</span>
            </h3>
            <ul className={`filter-list ${isUniExpanded ? "active" : ""}`}>
              <li>
                <input
                  type="button"
                  className="button-foundation"
                  value="AAT Sri Lanka"
                  onClick={() =>
                    handleFilterChange("university", "AAT Sri Lanka")
                  }
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-training"
                  value="SLIIT"
                  onClick={() => handleFilterChange("level", "SLIIT")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-certificate"
                  value="University of Colombo"
                  onClick={() =>
                    handleFilterChange("level", "University of Colombo")
                  }
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-diploma"
                  value="University of Moratuwa"
                  onClick={() =>
                    handleFilterChange("level", "University of Moratuwa")
                  }
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-advanced-diploma"
                  value="Western Sydney University"
                  onClick={() =>
                    handleFilterChange("level", "Western Sydney University")
                  }
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-hnd"
                  value="Curtin University"
                  onClick={() =>
                    handleFilterChange("level", "Curtin University")
                  }
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-degree"
                  value="IIT"
                  onClick={() => handleFilterChange("level", "IIT")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-bachelor"
                  value="CSCT Campus"
                  onClick={() => handleFilterChange("level", "CSCT Campus")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-postgraduate-diploma"
                  value="AIC"
                  onClick={() => handleFilterChange("level", "AIC")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-masters"
                  value="ESOFT"
                  onClick={() => handleFilterChange("level", "ESOFT")}
                />
              </li>
              <li>
                <input
                  type="button"
                  className="button-phd"
                  value="SLIIT"
                  onClick={() => handleFilterChange("level", "SLIIT")}
                />
              </li>
            </ul>
          </div>
        </div>

        <div className="right-column">
          {stdLoansToShow.map((loan) => (
            <div
              className="Course"
              key={loan.id}
              onClick={() => handleLoanClick(loan.id)}
            >
              <div className="calender">
                <div className="Date-top"></div>
                <div className="Date">
                  {new Date(loan.startDate).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                  })}
                </div>
              </div>
              <div className="Course-info">
                <a href={`/stdloanview/${loan.id}`}>
                  <strong>{loan.degreeName}</strong>
                </a>
                <p>
                  Start Date:{" "}
                  {new Date(loan.startDate).toLocaleDateString("en-CA")}
                </p>
                <p>Duration: {loan.duration}</p>
                <p>Level: {loan.level}</p>
                <p>Field: {loan.category}</p>
                <hr />
                <p1>
                  University:{" "}
                  <a href="#">
                    <strong>{loan.university}</strong>
                  </a>
                </p1>
              </div>
            </div>
          ))}
          {displayedLoansCount < stdLoans.length && (
            <button className="more-loans" onClick={handleSeeAllLoans}>
              SEE MORE LOANS
            </button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudentLoans;

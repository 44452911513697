import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './assets/css/styles.css';
import Main from './pages/Main';
import StdRegister from './pages/StdRegister';
import Courses from './pages/Courses';
import InstituteRegister from './pages/InstituteRegister';
import Institutes from './pages/Institutes';
import Event from './pages/Event';
import EventView from './pages/EventView';
import ContactUs from './pages/Contactus';
import AboutUs from './pages/AboutUs';
import StudyAbroad from './pages/StudyAbroad';
import StudyAbroadView from './pages/StudyAbroadView';
import GovCourses from './pages/GovCourses'; 
import PrivateCourses from './pages/PrivateCourses'; 
import GovUni from './pages/GovUni'; 
import GovUniView from './pages/GovUniView'; 
import CreateTuition from './pages/CreateTuition';
import Tuition from './pages/Tuition';
import TuitionView from './pages/TuitionView';
import CourseView from './pages/CourseView';
import RLogin from './pages/RLogin';
import InsView from './pages/InsView';
import Insfore from './pages/Insfore'; 
import ExternalDegree from './pages/ExternalDegree'; 
import ExternalDegreeView from './pages/ExternalDegreeView';
import CreateApplication from './pages/CreateApplication';
import Applications from './pages/Applications';
import ApplicationView from './pages/ApplicationView'; 
import StudentLoans from './pages/StudentLoans';
import StudentLoanView from './pages/StudentLoanView';
import BankCreateStLoan from './pages/BankCreateStLoan'; 
import Scholarships from './pages/Scholarships';
import ScholarshipView from './pages/ScholarshipView';
import LanguageQualification from './pages/LanguageQualification';
import LanguageQualificationView from './pages/LanguageQualificationView';
import ProfessionalExams from './pages/ProfessionalExams';
import ProfessionalExamView from './pages/ProfessionalExamView';
import RegisterIntSchls from './pages/RegisterIntSchls';
import InternationalSchls from './pages/InternationalSchls';
import InternatinalSchlView from './pages/InternatinalSchlView'; 
import Changepassf from './pages/Changepassf';
import Forgotpass from './pages/Forgotpass';
import Localjobsc from './pages/Localjobsc';
import LocaljobView from './pages/LocaljobView';
import ForeignJob from './pages/ForeignJob';
import GCView from './pages/GCView';
import ViewFjobOne from './pages/ViewFjobOne';
import AllForeignJobs from './pages/AllForeignJobs';
import AllLocalJobs from './pages/AllLocalJobs';

import Login from './pages/Admin/Login';
import SignUp from './pages/Admin/SignUp';
import AdminDashboard from './pages/Admin/AdminDashboard';
import AllCourses from './pages/Admin/AllCourses';
import PendingCourses from './pages/Admin/PendingCourses';
import DeleteCourses from './pages/Admin/DeleteCourses';
import InstituteAll from './pages/Admin/InstituteAll';
import PendingInstitute from './pages/Admin/PendingInstitute';
import DeleteInstitute from './pages/Admin/DeleteInstitute'; 
import StudentLoan from './pages/Admin/StudentLoan'; 
import SAPendingCourses from './pages/Admin/SAPendingCourses';
import SAAllCourses from './pages/Admin/SAAllCourses';
import SADeleteCourses from './pages/Admin/SADeleteCourses'; 
import AllStudents from './pages/Admin/AllStudents';
import AppliedForms from './pages/Admin/AppliedForms'; 
import PendingStudents from './pages/Admin/PendingStudents'; 
import DeleteStudents from './pages/Admin/DeleteStudents';  
import AllEvents from './pages/Admin/AllEvents';
import PendingEvents from './pages/Admin/PendingEvents';
import DeleteEvents from './pages/Admin/DeleteEvents';
import AllExternalDegrees from './pages/Admin/AllExternalDegrees';
import PendingExternalDegrees from './pages/Admin/PendingExternalDegrees';
import DeleteExternalDegrees from './pages/Admin/DeleteExternalDegrees';
import ACreateTuition from './pages/Admin/ACreateTuition';
import AllTuitions from './pages/Admin/AllTuitions';
import PendingTuitions from './pages/Admin/PendingTuitions';
import DeleteTuitions from './pages/Admin/DeleteTuitions';
import ACreateApplication from './pages/Admin/ACreateApplication';
import AllApplications from './pages/Admin/AllApplications';
import PendingApplications from './pages/Admin/PendingApplications';
import DeleteApplications from './pages/Admin/DeleteApplications';
import AllBankStdLoans from './pages/Admin/AllBankStdLoans';
import PendingBankStdLoans from './pages/Admin/PendingBankStdLoans';
import DeleteBankStdLoans from './pages/Admin/DeleteBankStdLoans';
import AllStdLoans from './pages/Admin/AllStdLoans';
import PendingStdLoans from './pages/Admin/PendingStdLoans';
import DeleteStdLoans from './pages/Admin/DeleteStdLoans';
import AllScholarships from './pages/Admin/AllScholarships';
import PendingScholarships from './pages/Admin/PendingScholarships';
import DeleteScholarships from './pages/Admin/DeleteScholarships';
import AllLangQual from './pages/Admin/AllLangQual';
import PendingLangQual from './pages/Admin/PendingLangQual';
import DeleteLangQual from './pages/Admin/DeleteLangQual';
import AllProfExams from './pages/Admin/AllProfExams';
import PendingProfExams from './pages/Admin/PendingProfExams';
import DeleteProfExams from './pages/Admin/DeleteProfExams';
import IntSchlProfile from './pages/Admin/IntSchlProfile';
import EditIntSchlProfile from './pages/Admin/EditIntSchlProfile';
import AllIntSchls from './pages/Admin/AllIntSchls';
import PendingIntSchls from './pages/Admin/PendingIntSchls';
import DeleteIntSchls from './pages/Admin/DeleteIntSchls';
import Localjoba from './pages/Admin/Localjoba';
import Localjobapen from './pages/Admin/Localjobapen';
import Allfjobs from './pages/Admin/Allfjobs';
import Allfpjobs from './pages/Admin/Allfpjobs';
import JobCateView from './pages/Admin/JobCateView';
import AdminViewDash from './pages/Admin/AdminViewDash';
import AdvertisementManage from './pages/Admin/AdvertisementManage';
import ADDAdvertisementManage from './pages/Admin/ADDAdvertisementManage';

import InstituteDashboard from './pages/Institute/InstituteDashboard';
import InstituteProfile from './pages/Institute/InstituteProfile';
import CreateCourse from './pages/Institute/CreateCourse'; 
import ManageCourse from './pages/Institute/ManageCourse';
import DeleteCourseIns from './pages/Institute/DeleteCourseIns';
import EditProfile from './pages/Institute/EditProfile';
import Changepass from './pages/Institute/Changepass';
import CreateEvent from './pages/Institute/CreateEvent'; 
import ManageEvents from './pages/Institute/ManageEvents';
import DeleteEventsIns from './pages/Institute/DeleteEventsIns';
import Appliedstudents from './pages/Institute/Appliedstudents';
import CreateExternalDegree from './pages/Institute/CreateExternalDegree'; 
import ManageExternalDegree from './pages/Institute/ManageExternalDegree';
import CreateStdLoans from './pages/Institute/CreateStdLoans'; 
import ManageStdLoans from './pages/Institute/ManageStdLoans';
import DelStdLoansIns from './pages/Institute/DelStdLoansIns';
import CreateScholarship from './pages/Institute/CreateScholarship'; 
import ManageScholarships from './pages/Institute/ManageScholarships';
import DelScholarshipsIns from './pages/Institute/DelScholarshipsIns';
import CreateLangQual from './pages/Institute/CreateLangQual'; 
import ManageLangQual from './pages/Institute/ManageLangQual';
import DeleteLangQualIns from './pages/Institute/DeleteLangQualIns';
import CreateProfExams from './pages/Institute/CreateProfExams'; 
import ManageProfExams from './pages/Institute/ManageProfExams';
import DeleteProfExamsIns from './pages/Institute/DeleteProfExamsIns';

import SADashboard from './pages/StudyAbroad/SADashboard';
import SAProfileView from './pages/StudyAbroad/SAProfileView';
import SACreate from './pages/StudyAbroad/SACreate';
import SAManageCourse from './pages/StudyAbroad/SAManageCourse';
import SADelCourse from './pages/StudyAbroad/SADelCourse';

import Governmentofficer from './pages/Gofficer/Governmentofficer';
import GCreate from './pages/Gofficer/GCreate';
import AllGC from './pages/Gofficer/AllGC';
import GuidanceProgram from './pages/GuidanceProgram';
import LJobCateView from './pages/Admin/LJobCateView';




const App = () => {
  
  return (
    <BrowserRouter>

      <Routes>
        <Route path="/" element={<Main />} ></Route>
        <Route path="/stdregister" element={<StdRegister />} ></Route>
        <Route path="/courses" element={<Courses />} ></Route>
        <Route path="/courseview/:id" element={<CourseView />} ></Route>
        <Route path='/insregister' element={<InstituteRegister/>} ></Route>
        <Route path="/institutes" element={<Institutes />} ></Route>
        <Route path="/institutesview/:id" element={<InsView/>} ></Route>
        <Route path='/events' element={<Event/>} ></Route>
        <Route path='/eventview/:id' element={<EventView/>} ></Route>
        <Route path='/contact' element={<ContactUs/>} />
        <Route path='/aboutus' element={<AboutUs/>} />
        <Route path='/studyabroad' element={<StudyAbroad/>} />
        <Route path='/studyabroadview/:id' element={<StudyAbroadView/>} />
        <Route path="/govcourses" element={<GovCourses />} /> 
        <Route path="/privatecourses" element={<PrivateCourses />} /> 
        <Route path="/govuni" element={<GovUni />} />
        <Route path="/govuniview" element={<GovUniView />} />
        <Route path="/createtuition" element={<CreateTuition />} />
        <Route path="/tuition" element={<Tuition />} />
        <Route path="/tuitionview/:id" element={<TuitionView />} />
        <Route path="/rlogin" element={<RLogin/>} />
        <Route path="/insfore" element={<Insfore/>}/> 
        <Route path="/externaldegree" element={<ExternalDegree/>}/>
        <Route path="/edview/:id" element={<ExternalDegreeView />} />
        <Route path='/localjob' element={<Localjobsc/>} />
        <Route path='/localjview/:id' element={<LocaljobView/>} />
        <Route path="/createapplication" element={<CreateApplication />} />
        <Route path="/applications" element={<Applications />} />
        <Route path="/applicationview/:id" element={<ApplicationView />} />
        <Route path="/stdloans" element={<StudentLoans />} /> 
        <Route path="/stdloanview/:id" element={<StudentLoanView />} />
        <Route path="/bankcreatestdloan" element={<BankCreateStLoan />} />
        <Route path="/scholarships" element={<Scholarships />} />
        <Route path="/scholarshipview/:id" element={<ScholarshipView />} />
        <Route path="/languagequalifications" element={<LanguageQualification />} />
        <Route path="/languagequalificationview/:id" element={<LanguageQualificationView />} />
        <Route path="/professionalexams" element={<ProfessionalExams />} />
        <Route path="/professionalexamview/:id" element={<ProfessionalExamView />} />
        <Route path="/registerintschls" element={<RegisterIntSchls />} />
        <Route path="/intschls" element={<InternationalSchls />} />
        <Route path="/intschlsview/:id" element={<InternatinalSchlView />} />
        <Route path="/gcview" element={<GCView/>} />
        <Route path="/foreign" element={<ForeignJob/>} />
        <Route path="/fjob/:id" element={<ViewFjobOne/>} />
        <Route path="/allforeignjobs" element={<AllForeignJobs/>} />
        <Route path="/alllocaljobs" element={<AllLocalJobs/>} />
        <Route path="/Guidanceprogram" element={<GuidanceProgram/>}/>


        {/* Admin dashboard */}
        <Route path='/login' element={<Login/>} />
        <Route path='/signUp' element={<SignUp/>} />
        <Route path='/admin' element={<AdminDashboard/>}/>
        <Route path='/allcourses' element={<AllCourses/>}/>
        <Route path='/pendingcourses' element={<PendingCourses/>}/>
        <Route path='/deletecourses' element={<DeleteCourses/>}/>
        <Route path='/peninstitute' element={<InstituteAll/>}/>
        <Route path='/allinstitute' element={<PendingInstitute/>}/>
        <Route path='/delInstitute' element={<DeleteInstitute/>}/>
        <Route path='/stdloan' element={<StudentLoan/>}/>
        <Route path='/sapendingcourses' element={<SAPendingCourses/>}/>
        <Route path='/saallcourses' element={<SAAllCourses/>}/>
        <Route path='/sadeletecourses' element={<SADeleteCourses/>}/>
        <Route path='/allstudents' element={<AllStudents/>}/>
        <Route path='/appliedforms' element={<AppliedForms/>}/>
        <Route path='/penstudents' element={<PendingStudents/>}/> 
        <Route path='/delstudents' element={<DeleteStudents/>}/> 
        <Route path='/allevents' element={<AllEvents/>}/>
        <Route path='/pendingevents' element={<PendingEvents/>}/>
        <Route path='/delevents' element={<DeleteEvents/>}/> 
        <Route path='/allexternaldeg' element={<AllExternalDegrees/>}/>
        <Route path='/pendingexternaldeg' element={<PendingExternalDegrees/>}/>
        <Route path='/delexternaldeg' element={<DeleteExternalDegrees/>}/> 
        <Route path='/localjoba' element={<Localjoba/>} />
        <Route path='/localjobapen' element={<Localjobapen/>  } />  
        <Route path="/admincreatetuition" element={<ACreateTuition />} />
        <Route path='/alltuition' element={<AllTuitions/>}/>
        <Route path='/pendingtuition' element={<PendingTuitions/>}/>
        <Route path='/deltuition' element={<DeleteTuitions/>}/> 
        <Route path="/admincreateapplication" element={<ACreateApplication />} />
        <Route path='/allapplications' element={<AllApplications/>}/>
        <Route path='/pendingapplications' element={<PendingApplications/>}/>
        <Route path='/delapplications' element={<DeleteApplications/>}/> 
        <Route path='/allbankstdloan' element={<AllBankStdLoans/>}/>
        <Route path='/pendingbankstdloan' element={<PendingBankStdLoans/>}/>
        <Route path='/delbankstdloan' element={<DeleteBankStdLoans/>}/> 
        <Route path='/allstdloan' element={<AllStdLoans/>}/>
        <Route path='/pendingstdloan' element={<PendingStdLoans/>}/>
        <Route path='/delstdloan' element={<DeleteStdLoans/>}/> 
        <Route path='/allscholarships' element={<AllScholarships/>}/>
        <Route path='/pendingscholarships' element={<PendingScholarships/>}/>
        <Route path='/deletescholarships' element={<DeleteScholarships/>}/> 
        <Route path='/alllangqual' element={<AllLangQual/>}/>
        <Route path='/pendinglangqual' element={<PendingLangQual/>}/>
        <Route path='/dellangqual' element={<DeleteLangQual/>}/> 
        <Route path='/allprofexams' element={<AllProfExams/>}/>
        <Route path='/pendingprofexams' element={<PendingProfExams/>}/>
        <Route path='/deleteprofexams' element={<DeleteProfExams/>}/> 
        <Route path='/intschlprofile' element={<IntSchlProfile/>}/>
        <Route path='/editintschlprofile/:id' element={<EditIntSchlProfile/>}/> 
        <Route path='/allintschl' element={<AllIntSchls/>}/>
        <Route path='/pendingsintschl' element={<PendingIntSchls/>}/>
        <Route path='/deleteintschl' element={<DeleteIntSchls/>}/> 
        <Route path='/allfjob' element={<Allfjobs/>}/>
        <Route path='/allfpjob' element={<Allfpjobs/>}/>
        <Route path='/fjobcate' element={<JobCateView/>} />
        <Route path='/admindc' element={<AdminViewDash/>}/>
        <Route path='/advertise' element={<AdvertisementManage/>}/>
        <Route path="/addadvertise" element={<ADDAdvertisementManage />} />
        <Route path="/ljobcate" element={<LJobCateView/>} />
       

        {/* Institute Dashboard */}
        <Route path='/insdashboard' element={<InstituteDashboard/>} />
        <Route path='/insprofile' element={<InstituteProfile/>} />
        <Route path='/inscourses' element={<CreateCourse/>} />  
        <Route path='/editprofile/:id' element={<EditProfile/>} />
        <Route path='/mancourses' element={<ManageCourse/>} />
        <Route path='/delcourses' element={<DeleteCourseIns/>} />
        <Route path='/changepass' element={<Changepass/>} /> 
        <Route path='/createevent' element={<CreateEvent/>} /> 
        <Route path='/manevents' element={<ManageEvents/>} />
        <Route path='/deleventsins' element={<DeleteEventsIns/>} /> 
        <Route path='/createexdeg' element={<CreateExternalDegree/>} /> 
        <Route path='/manexdeg' element={<ManageExternalDegree/>} />
        <Route path='/Apstudents' element={<Appliedstudents/>}/> 
        <Route path='/createstdloan' element={<CreateStdLoans/>} /> 
        <Route path='/manstdloan' element={<ManageStdLoans/>} />
        <Route path='/delstdloanins' element={<DelStdLoansIns/>} /> 
        <Route path='/createscholarship' element={<CreateScholarship/>} /> 
        <Route path='/manscholarship' element={<ManageScholarships/>} />
        <Route path='/delscholarshipins' element={<DelScholarshipsIns/>} /> 
        <Route path='/createlangqual' element={<CreateLangQual/>} /> 
        <Route path='/manlangqual' element={<ManageLangQual/>} />
        <Route path='/delinslangqual' element={<DeleteLangQualIns/>} /> 
        <Route path='/createprofexams' element={<CreateProfExams/>} /> 
        <Route path='/manprofexams' element={<ManageProfExams/>} />
        <Route path='/delprofexamsins' element={<DeleteProfExamsIns/>} /> 

        
        {/* Study Abroad Dashboard */}
        <Route path='/sadashboard' element={<SADashboard/>} />
        <Route path='/saprofile' element={<SAProfileView/>} />
        <Route path='/sacourses' element={<SACreate/>} />  
        <Route path='/samanagecourses' element={<SAManageCourse/>} />  
        <Route path='/sadelcourses' element={<SADelCourse/>} />  


        {/* Government Officer */}
        <Route path='/gofficer' element={<Governmentofficer/>}/>
        <Route path='/cgofficer' element={<GCreate/>}/>
        <Route path='/allgc' element={<AllGC/>}/>


        {/* change password */}
        <Route path='/changepassf/:id' element={<Changepassf/>} />
        <Route path='/forgot' element={<Forgotpass/>} />


      </Routes>
    </BrowserRouter>
  );
};

export default App;

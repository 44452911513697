import React from 'react'

const StudentLoan = () => {
  return (
    <div>
      STUDENT LOANS
    </div>
  )
}

export default StudentLoan

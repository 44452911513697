import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InstituteDashboard from "./InstituteDashboard";
import "../../assets/css/Institute/CreateCourse.css";
import axios from "axios";
import Swal from "sweetalert2";

const CreateStdLoans = () => {
  const institute_id = sessionStorage.getItem("id");

  const [formData, setFormData] = useState({
    logo: null,
    university: "",
    faculty: "",
    department: "",
    degreeName: "",
    category: "",
    level: "",
    duration: "",
    startDate: "",
    specification: "",
    careerOpportunities: "",
    industry: "",
    description: "",
    instituteAddress: "",
    institutePhone: "",
    instituteEmail: "",
    instituteLocation: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInstituteDetails = async () => {
      try {
        const response = await axios.get(
          `https://skillbackend.educationesupport.xyz//ins/one/${institute_id}`
        );
        const institute = response.data[0];
        setFormData((prevData) => ({
          ...prevData,
          university: institute.in_name || "",
          institutePhone: institute.tel || "",
          instituteAddress: institute.address || "",
          instituteEmail: institute.email || "",
        }));
      } catch (error) {
        console.error("Error fetching institute details:", error);
      }
    };
    if (institute_id) {
      fetchInstituteDetails();
    } else {
      console.error("No institute ID found in session storage.");
    }
  }, [institute_id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    for (const key in formData) {
      if (formData[key] !== null) {
        formDataToSend.append(key, formData[key]);
      }
    }

    if (institute_id) {
      formDataToSend.append("institute_id", institute_id);
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "No Institute ID",
        text: "Institute ID is missing. Please try again.",
        showConfirmButton: true,
      });
      return;
    }

    fetch("https://skillbackend.educationesupport.xyz//stdLoans/create", {
      method: "POST",
      body: formDataToSend,
    })
      .then((response) => {
        if (response.status === 400) {
          return response.text().then((message) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: message,
              showConfirmButton: true,
            });
          });
        } else if (response.ok) {
          return response.text().then((data) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Application Added Successfully",
              text: "Your application has been added successfully.",
              showConfirmButton: false,
              timer: 2500,
            }).then(() => {
              navigate("/manstdloan");
            });
          });
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          showConfirmButton: true,
        });
      });
  };

  return (
    <main className="institute-create-course">
      <InstituteDashboard />
      <div className="head-content-text">
        <h4>ADD STUDENT LOAN</h4>
      </div>
      <div className="r_container">
        <form onSubmit={handleSubmit}>
          <div className="new">
            <div className="formgroup">
              <label htmlFor="file-input" className="drop-container">
                <span className="drop-title">Drop Bank Logo</span>
                <input
                  type="file"
                  accept="image/*"
                  id="file-input"
                  name="logo"
                  onChange={handleChange}
                />
              </label>
            </div>

            <div className="formgroup">
              <i className="fas fa-building"></i>
              <input
                type="text"
                id="university"
                name="university"
                value={formData.university}
                onChange={handleChange}
                placeholder="University / Institute Name"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-chalkboard-teacher"></i>
              <input
                type="text"
                id="faculty"
                name="faculty"
                value={formData.faculty}
                onChange={handleChange}
                placeholder="Faculty"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-building"></i>
              <input
                type="text"
                id="department"
                name="department"
                value={formData.department}
                onChange={handleChange}
                placeholder="Department"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-book"></i>
              <input
                type="text"
                id="degreeName"
                name="degreeName"
                value={formData.degreeName}
                onChange={handleChange}
                placeholder="Course/ Degree Name"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-book-reader"></i>
              <select
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Course Category
                </option>
                <option value="Business">Business</option>
                <option value="Engineering">Engineering</option>
                <option value="Science">Science</option>
                <option value="Arts">Arts</option>
                <option value="Technology">IT</option>
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-layer-group"></i>
              <select
                id="level"
                name="level"
                value={formData.level}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Course Level
                </option>
                <option value="Foundation">Foundation</option>
                <option value="Training">Training</option>
                <option value="Certificate">Certificate</option>
                <option value="Diploma">Diploma</option>
                <option value="Advanced Diploma">Advanced Diploma</option>
                <option value="HND">HND</option>
                <option value="Degree">Degree</option>
                <option value="Postgraduate Diploma">
                  Postgraduate Diploma
                </option>
                <option value="Master's">Master's</option>
                <option value="PhD">PhD</option>
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-hourglass-start"></i>
              <input
                type="text"
                id="duration"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                placeholder="Duration"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-calendar-alt"></i>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-cogs"></i>
              <input
                type="text"
                id="specification"
                name="specification"
                value={formData.specification}
                onChange={handleChange}
                placeholder="Specification"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-briefcase"></i>
              <input
                type="text"
                id="careerOpportunities"
                name="careerOpportunities"
                value={formData.careerOpportunities}
                onChange={handleChange}
                placeholder="Career Opportunities"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-industry"></i>
              <input
                type="text"
                id="industry"
                name="industry"
                value={formData.industry}
                onChange={handleChange}
                placeholder="Industry"
              />
            </div>

            <div className="formgroup textarea">
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Student Loan Description"
              ></textarea>
            </div>

            <div className="formgroup">
              <i className="fas fa-address-book"></i>
              <input
                type="text"
                id="instituteAddress"
                name="instituteAddress"
                value={formData.instituteAddress}
                onChange={handleChange}
                placeholder="Institute Address"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-phone"></i>
              <input
                type="text"
                id="institutePhone"
                name="institutePhone"
                value={formData.institutePhone}
                onChange={handleChange}
                placeholder="Institute Phone"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-envelope"></i>
              <input
                type="text"
                id="instituteEmail"
                name="instituteEmail"
                value={formData.instituteEmail}
                onChange={handleChange}
                placeholder="Institute Email"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-location-arrow"></i>
              <input
                type="text"
                id="instituteLocation"
                name="instituteLocation"
                value={formData.instituteLocation}
                onChange={handleChange}
                placeholder="Institute Location"
              />
            </div>
          </div>
          <button type="submit">Add</button>
        </form>
      </div>
    </main>
  );
};

export default CreateStdLoans;
